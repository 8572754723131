@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/pux-content.scss";
@import "_mixins/pux/scale.scss";
@import "_variables.scss";

.people {
  display: flex;
  flex-wrap: wrap;
  margin: 8rem 0 11rem;

  .item {
    display: flex;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 500px;
    margin-bottom: 5rem;

    @include media(lg) {
      width: 33.333%;
      margin-bottom: 0;
    };

    .info {
      max-width: 60rem;
      padding-left: 2.5rem;
      font-style: italic;
      line-height: 24px;
      padding-top: 3rem;

      @include media(lg) {
        max-width: 23rem;
      };
    }

    .name {
      @include pux-scale-with-min("font-size", 25px, 20px);
      @include pux-scale-with-min("line-height", 28px, 20px);
      font-weight: bold;
      color: $color-blue;
      font-style: normal;
      margin-bottom: 1.5rem;
    }

    .img {
      width: 20rem;
      min-width: 20rem;

      img {
        @include media(lg) {
          height: 280px !important;
        };
      }
    }
  }
}
