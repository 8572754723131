
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/pux-content.scss";
@import "_mixins/pux/scale.scss";
@import "_variables.scss";

.header-block {
  background-color: $base-white;
  background-image: linear-gradient(-125deg, $base-white 25%, #e7eaef 100%);
  padding: 20rem 0 9rem;
  position: relative;
  overflow: hidden;

  @include media(lg) {
    min-height: 72rem;
    display: flex;
    align-items: center;
  };

  &:before {
    content: "";
    width: 74rem;
    height: 74rem;
    background-color: #ffffff;
    border-radius: 50%;
    position: absolute;
    z-index: 0;
    top: 17.5rem;
    left: 45%;
  }

  &-inner {
    display: flex;
    flex-wrap: wrap;
    position: relative;
  }

  &-text {
    width: 100%;
    @include media(lg) {
      max-width: 57rem;
    };
  }

  &-img {
    width: 100%;

    img {
      margin-top: 4rem;

      @include media(lg) {
        margin-top: 0;
        position: absolute;
        top: 50%;
        transform: translate3d(0,-50%,0);
        max-width: 75%;
        right: 0;
      };
    }

    &.hp-position-helper {

      img {

        @include media(lg) {
          right: -4rem;
        };
      }
    }
  }

  &.smaller-image {
    .header-block-img img {
      @include media(lg) {
        max-width: 52%;
      };

      @include media(xxl) {
        max-width: 60%;
      };
    }
  }

  h1 {
    margin-bottom: 3rem;
  }

  big {
    display: block;
    margin-bottom: 2rem;
  }

  .btn {
    min-width: 25rem;
    margin: 9px 0;
    padding: 2rem 3rem;
  }

  .pux-container {
    position: relative;
    width: 100%;
  }

  &.simple {
    min-height: 1rem;
    padding: 21rem 0 5rem;

    .header-block-text {
      width: 100%;
      max-width: 100%;
    }

    h1 {
      margin: 0;
      text-align: center;
    }
  }
}

.negative-bottom {

  .header-block {
    padding-bottom: 13rem;
  }

  margin-bottom: -4rem;
}

.page-builder {

  .header-block {
    overflow: visible !important;
  }
}

.IE {

  .header-block {

    &-img {

      img {

        @include media(lg) {
          top: 190px;
        };
      }
    }
  }
}
