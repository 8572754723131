
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/pux-content.scss";
@import "_mixins/pux/scale.scss";
@import "_variables.scss";

.img-bg {
  position: relative;
  overflow: hidden;

  &:before, &:after {
    content: "";
    width: 500px;
    height: 100%;
    position: absolute;
    z-index: 0;
    top: 50%;
    transform: translateY(-50%);
    background-repeat: no-repeat !important;
    background-position: center 100% !important;
  }

  &.grey {
    &:before, &:after {
      background-position: center center !important;
    }
  }

  &:before {
    background: url('../img/summary-left.png');
    left: -350px;

    @include media(xxl) {
      left: 0;
    };
  }

  &:after {
    background: url('../img/summary-right.png');
    right: -350px;

    @include media(xxl) {
      right: 0;
    };
  }
}

.summary {
  max-width: 100rem;
  margin: 5rem auto 0;

  &-item {
    @include flexCenter();
    flex-wrap: wrap;
    margin-top: 2rem;

    &-text, &-img {
      width: 100%;
      @include flexCenter();
      flex-flow: column;
      padding-left: 2rem;
      padding-right: 2rem;

      @include media(md) {
        width: 50%;
      };
    }

    &-text {
      order: 4;
      text-align: center;

      @include media(md) {
        text-align: left;
      };

      .text {
        max-width: 100%;
      }
    }

    &-img {
      padding: 4rem 6rem;
      order: 3;
    }

    &:nth-of-type(even) {

      .summary-item-img {
        @include media(md) {
          order: 5;
        };
      }
    }
  }

  h3 {
    margin-bottom: 1rem;
    width: 100%;
  }
}
