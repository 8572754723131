@import url("https://use.typekit.net/zyh6dah.css");
.selectric-wrapper {
  position: relative;
  cursor: pointer;
  margin-bottom: 0; }

.selectric-responsive {
  width: 100%; }

.selectric {
  background: #fff;
  position: relative;
  overflow: hidden;
  border: 1px solid #e1e1e1;
  font-weight: 400;
  text-transform: none;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: space-between;
  -moz-box-pack: space-between;
  -ms-flex-pack: space-between;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  font-size: 3.2rem; }
  @media (min-width: 480px) {
    .selectric {
      font-size: 2.90909rem; } }
  @media (min-width: 545px) {
    .selectric {
      font-size: 2.66667rem; } }
  @media (min-width: 768px) {
    .selectric {
      font-size: 2.13333rem; } }
  @media (min-width: 992px) {
    .selectric {
      font-size: 2rem; } }
  @media (min-width: 1200px) {
    .selectric {
      font-size: 1.77778rem; } }
  @media (min-width: 1400px) {
    .selectric {
      font-size: 1.6rem; } }
  .selectric .label {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #2b2b2b;
    user-select: none;
    padding: 0 2rem 0 2rem; }
    @media (min-width: 320px) {
      .selectric .label {
        line-height: 8rem; } }
    @media (min-width: 480px) {
      .selectric .label {
        line-height: 7.27273rem; } }
    @media (min-width: 545px) {
      .selectric .label {
        line-height: 6.66667rem; } }
    @media (min-width: 768px) {
      .selectric .label {
        line-height: 5.33333rem; } }
    @media (min-width: 992px) {
      .selectric .label {
        line-height: 5rem; } }
    @media (min-width: 320px) {
      .selectric .label {
        height: 8rem; } }
    @media (min-width: 480px) {
      .selectric .label {
        height: 7.27273rem; } }
    @media (min-width: 545px) {
      .selectric .label {
        height: 6.66667rem; } }
    @media (min-width: 768px) {
      .selectric .label {
        height: 5.33333rem; } }
    @media (min-width: 992px) {
      .selectric .label {
        height: 5rem; } }
  .selectric .button {
    display: block;
    position: relative;
    right: 0;
    top: 0;
    width: 6rem;
    height: 100%;
    text-align: center;
    transition: all ease 200ms;
    font-size: 0;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center; }
    @media (min-width: 480px) {
      .selectric .button {
        width: 5.45455rem; } }
    @media (min-width: 545px) {
      .selectric .button {
        width: 5rem; } }
    @media (min-width: 768px) {
      .selectric .button {
        width: 4rem; } }
    @media (min-width: 992px) {
      .selectric .button {
        width: 3.75rem; } }
    @media (min-width: 1200px) {
      .selectric .button {
        width: 3.33333rem; } }
    @media (min-width: 1400px) {
      .selectric .button {
        width: 3rem; } }
    .selectric .button:after {
      font-size: 3rem;
      content: "";
      color: #2b2b2b; }
      @media (min-width: 480px) {
        .selectric .button:after {
          font-size: 2.72727rem; } }
      @media (min-width: 545px) {
        .selectric .button:after {
          font-size: 2.5rem; } }
      @media (min-width: 768px) {
        .selectric .button:after {
          font-size: 2rem; } }
      @media (min-width: 992px) {
        .selectric .button:after {
          font-size: 1.875rem; } }
      @media (min-width: 1200px) {
        .selectric .button:after {
          font-size: 1.66667rem; } }
      @media (min-width: 1400px) {
        .selectric .button:after {
          font-size: 1.5rem; } }

.selectric-focus .selectric {
  border-color: #8bbe1c; }

.selectric-open {
  z-index: 9999; }
  .selectric-open .selectric {
    border-color: #8bbe1c;
    border-bottom: 1px solid #8bbe1c; }
    .selectric-open .selectric .button {
      -ms-transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg); }
  .selectric-open .selectric-items {
    display: block;
    border-color: #8bbe1c;
    border-top: 1px solid #8bbe1c; }

.selectric-disabled {
  opacity: 0.5;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  filter: alpha(opacity=50);
  cursor: default;
  user-select: none; }

.selectric-hide-select {
  position: relative;
  overflow: hidden;
  width: 0;
  height: 0; }
  .selectric-hide-select select {
    position: absolute;
    left: -100%; }
  .selectric-hide-select.selectric-is-native {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10; }
    .selectric-hide-select.selectric-is-native select {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
      border: none;
      z-index: 1;
      box-sizing: border-box;
      opacity: 0; }

.selectric-input {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  outline: none !important;
  border: none !important;
  *font: 0/0 a !important;
  background: none !important; }

.selectric-temp-show {
  position: absolute !important;
  visibility: hidden !important;
  display: block !important; }

/* Items box */
.selectric-items {
  overflow: hidden;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #e6e6e6;
  border: 2px solid #e1e1e1;
  border-top: 1px;
  z-index: -1; }
  .selectric-items .selectric-scroll {
    height: 100%;
    overflow: auto; }
  .selectric-above .selectric-items {
    top: auto;
    bottom: 100%; }
  .selectric-items ul, .selectric-items li {
    list-style: none;
    padding: 0;
    margin: 0;
    line-height: 2rem;
    min-height: 2rem; }
  .selectric-items li {
    display: block;
    padding: 1rem 2rem;
    color: #2b2b2b;
    background: #f2f2f2;
    cursor: pointer;
    transition: all ease 200ms; }
    .selectric-items li:before {
      display: none; }
    .selectric-items li.selected {
      color: #2b2b2b; }
    .selectric-items li.highlighted {
      color: #2b2b2b; }
    .selectric-items li:hover {
      background: #ededed; }
    .selectric-items li:nth-child(odd) {
      background: #e6e6e6; }
      .selectric-items li:nth-child(odd):hover {
        background: #e0e0e0; }
  .selectric-items .disabled {
    opacity: 0.5;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    filter: alpha(opacity=50);
    cursor: default !important;
    background: none !important;
    color: #2b2b2b !important;
    user-select: none; }
  .selectric-items .selectric-group .selectric-group-label {
    font-weight: bold;
    padding-left: 1rem;
    cursor: default;
    user-select: none;
    background: none;
    color: #2b2b2b; }
  .selectric-items .selectric-group.disabled li {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100); }
  .selectric-items .selectric-group li {
    padding-left: 2.5rem; }

.list-box .selectric-scroll ul li {
  padding: 1rem 2rem 1rem 1rem; }
  .list-box .selectric-scroll ul li:before {
    content: "";
    width: 12px;
    height: 12px;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0;
    display: inline-block;
    margin: 0 1rem 0 0;
    background: #cccccc;
    vertical-align: middle;
    position: static;
    border: 2px solid #cccccc; }
  .list-box .selectric-scroll ul li.selected:before {
    background: #274c7f; }
  .list-box .selectric-scroll ul li:first-child:before {
    display: none; }

.pux-container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  max-width: 145rem; }
  .pux-container:after {
    display: block;
    content: "";
    clear: both; }
  @media (min-width: 768px) {
    .pux-container {
      padding-left: 3rem;
      padding-right: 3rem; } }

.pux-container .row {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px; }
  @media (min-width: 768px) {
    .pux-container .row {
      margin-left: -3rem;
      margin-right: -3rem; } }

.pux-container [class^="col"] {
  padding-left: 15px;
  padding-right: 15px; }
  @media (min-width: 768px) {
    .pux-container [class^="col"] {
      padding-left: 3rem;
      padding-right: 3rem; } }

.pux-fluid-container {
  padding-left: 15px;
  padding-right: 15px; }
  @media (min-width: 768px) {
    .pux-fluid-container {
      padding-left: 3rem;
      padding-right: 3rem; } }
  @media (min-width: 1200px) {
    .pux-fluid-container {
      padding-left: 12rem;
      padding-right: 12rem; } }

header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  transition: all ease-out 250ms;
  z-index: 50;
  border-bottom: 1px solid transparent; }
  header .header {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: space-between;
    -moz-box-pack: space-between;
    -ms-flex-pack: space-between;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    padding: 4.5rem 0;
    transition: all ease-out 250ms; }
    header .header-logo {
      transform: translateY(-1rem); }
      header .header-logo a {
        display: inline-block; }
        header .header-logo a span {
          display: none; }
        header .header-logo a img {
          height: 5.3rem;
          width: 23.8rem; }
    header .header-menu {
      position: absolute;
      top: 14.3rem;
      z-index: 77;
      left: 0;
      background: #f4f6f8;
      width: 100%;
      padding: 3rem 4rem;
      transition: all ease-out 200ms;
      z-index: -1;
      opacity: 0;
      transform: translate3d(0, -1.5rem, 0);
      pointer-events: none;
      max-height: -webkit-calc(100vh - 5rem);
      max-height: -moz-calc(100vh - 5rem);
      max-height: calc(100vh - 5rem); }
      @media (min-width: 768px) {
        header .header-menu {
          padding: 0;
          z-index: 1;
          opacity: 1;
          transform: translate3d(0, 0, 0);
          pointer-events: all;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          position: static;
          background: none;
          width: auto; } }
      header .header-menu ul {
        margin: 0;
        padding: 0;
        list-style: none; }
        @media (min-width: 768px) {
          header .header-menu ul {
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex; } }
        header .header-menu ul li {
          display: block;
          position: relative; }
          @media (min-width: 768px) {
            header .header-menu ul li {
              margin: 0 1rem;
              display: -ms-flexbox;
              display: -webkit-flex;
              display: flex;
              -webkit-box-align: center;
              -moz-box-align: center;
              -ms-flex-align: center;
              -webkit-align-items: center;
              align-items: center; } }
          header .header-menu ul li a {
            display: block;
            padding: 1.5rem 1.5rem;
            color: #274c7f;
            text-transform: uppercase;
            font-weight: bold;
            letter-spacing: 1px;
            font-size: 3.2rem; }
            @media (min-width: 480px) {
              header .header-menu ul li a {
                font-size: 2.90909rem; } }
            @media (min-width: 545px) {
              header .header-menu ul li a {
                font-size: 2.66667rem; } }
            @media (min-width: 768px) {
              header .header-menu ul li a {
                font-size: 2.13333rem; } }
            @media (min-width: 992px) {
              header .header-menu ul li a {
                font-size: 2rem; } }
            @media (min-width: 1200px) {
              header .header-menu ul li a {
                font-size: 1.77778rem; } }
            @media (min-width: 1400px) {
              header .header-menu ul li a {
                font-size: 1.6rem; } }
            header .header-menu ul li a:focus, header .header-menu ul li a:hover {
              outline-color: none;
              color: #8bbe1c;
              text-decoration: none; }
          header .header-menu ul li.active a {
            color: #8bbe1c; }
          header .header-menu ul li ul {
            background: #f4f6f8;
            display: none;
            width: 100%;
            opacity: 1;
            -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
            filter: alpha(opacity=100);
            visibility: visible;
            -moz-transition: all ease-in-out 200ms;
            -o-transition: all ease-in-out 200ms;
            -webkit-transition: all ease-in-out 200ms;
            transition: all ease-in-out 200ms; }
            @media (min-width: 768px) {
              header .header-menu ul li ul {
                position: absolute;
                width: 20rem;
                visibility: hidden;
                opacity: 0;
                -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
                filter: alpha(opacity=0);
                display: block;
                top: 100%; } }
          header .header-menu ul li.hovered > ul, header .header-menu ul li:hover > ul {
            visibility: visible;
            opacity: 1;
            -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
            filter: alpha(opacity=100); }
          header .header-menu ul li.open .menu-openner:after {
            -ms-transform: rotate(0deg);
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
          header .header-menu ul li.open ul {
            display: block; }
      header .header-menu .menu-openner {
        position: absolute;
        top: 0;
        right: 0;
        background: #274c7f;
        color: #fff;
        width: 6rem;
        height: 6rem; }
        @media (min-width: 768px) {
          header .header-menu .menu-openner {
            display: none; } }
        header .header-menu .menu-openner:after, header .header-menu .menu-openner:before {
          content: "";
          width: 70%;
          height: 2px;
          background: #fff;
          position: absolute;
          left: 15%;
          top: 50%;
          margin-top: -1px; }
        header .header-menu .menu-openner:after {
          -ms-transform: rotate(90deg);
          -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
          -moz-transition: all ease-in-out 200ms;
          -o-transition: all ease-in-out 200ms;
          -webkit-transition: all ease-in-out 200ms;
          transition: all ease-in-out 200ms; }
    @media (min-width: 768px) {
      header .header-hamburger {
        display: none; } }
    header .header-hamburger .open-menu {
      width: 6rem;
      height: 6rem;
      position: relative;
      display: block; }
      header .header-hamburger .open-menu span {
        width: 5rem;
        position: absolute;
        left: 0.5rem;
        height: 2px;
        background: #274c7f;
        margin-top: -1px;
        top: 50%;
        -moz-transition: all ease-in-out 200ms;
        -o-transition: all ease-in-out 200ms;
        -webkit-transition: all ease-in-out 200ms;
        transition: all ease-in-out 200ms; }
        header .header-hamburger .open-menu span:before, header .header-hamburger .open-menu span:after {
          content: "";
          width: 5rem;
          left: 0;
          height: 2px;
          background: #274c7f;
          position: absolute;
          -moz-transition: all ease-in-out 200ms;
          -o-transition: all ease-in-out 200ms;
          -webkit-transition: all ease-in-out 200ms;
          transition: all ease-in-out 200ms; }
        header .header-hamburger .open-menu span:before {
          top: -1rem; }
        header .header-hamburger .open-menu span:after {
          bottom: -1rem; }
    header .header.open .header-hamburger .open-menu span {
      background: transparent; }
      header .header.open .header-hamburger .open-menu span:before {
        top: 0;
        -ms-transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg); }
      header .header.open .header-hamburger .open-menu span:after {
        top: 0;
        -ms-transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg); }
    header .header.open .header-menu {
      z-index: 1;
      opacity: 1;
      transform: translate3d(0, 0, 0);
      pointer-events: all; }
  header.scrolled {
    background: #fff;
    border-bottom: 1px solid #f4f6f8; }
    header.scrolled .header {
      padding: 1.5rem 0; }
      header.scrolled .header .header-menu {
        top: 8.3rem; }

.EditMode header,
.DesignMode header {
  position: static; }

footer {
  font-size: 3.2rem;
  padding: 5rem 0 3rem;
  background: #274c7f;
  color: #fff; }
  @media (min-width: 480px) {
    footer {
      font-size: 2.90909rem; } }
  @media (min-width: 545px) {
    footer {
      font-size: 2.66667rem; } }
  @media (min-width: 768px) {
    footer {
      font-size: 2.13333rem; } }
  @media (min-width: 992px) {
    footer {
      font-size: 2rem; } }
  @media (min-width: 1200px) {
    footer {
      font-size: 1.77778rem; } }
  @media (min-width: 1400px) {
    footer {
      font-size: 1.6rem; } }
  footer .footer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }
    footer .footer-col {
      margin: 0 3rem 3rem 0; }
      @media (min-width: 1200px) {
        footer .footer-col {
          margin: 0 0 3rem 0; } }
      footer .footer-col.smaller {
        max-width: 160px; }
        footer .footer-col.smaller:first-of-type {
          margin-right: 6rem; }
      footer .footer-col.with-cols {
        max-width: 430px; }
        @media (min-width: 1200px) {
          footer .footer-col.with-cols {
            margin-left: 15rem; } }
        footer .footer-col.with-cols .footer-col-inner {
          display: flex;
          flex-wrap: wrap; }
          footer .footer-col.with-cols .footer-col-inner .top {
            width: 100%; }
          footer .footer-col.with-cols .footer-col-inner .left, footer .footer-col.with-cols .footer-col-inner .right {
            width: 100%; }
            @media (min-width: 480px) {
              footer .footer-col.with-cols .footer-col-inner .left, footer .footer-col.with-cols .footer-col-inner .right {
                width: 50%; } }
        footer .footer-col.with-cols a {
          font-weight: 400;
          display: block; }
      footer .footer-col.last {
        text-align: right;
        margin-left: auto;
        margin-right: 0; }
        footer .footer-col.last img {
          margin-bottom: 30px; }
        footer .footer-col.last .separator {
          margin: 0 12px; }
        footer .footer-col.last a {
          color: #8bbe1c; }
          footer .footer-col.last a:hover, footer .footer-col.last a:focus {
            text-decoration: underline; }
  footer a {
    color: #fff;
    font-weight: 400; }
    footer a:hover, footer a:focus {
      text-decoration: none; }
  footer ul {
    margin: 0;
    padding: 0;
    list-style-type: none; }
  footer li {
    margin-bottom: 1.5rem; }

.is-sm {
  display: none; }
  @media (min-width: 545px) {
    .is-sm {
      display: block; } }

.is-md {
  display: none; }
  @media (min-width: 768px) {
    .is-md {
      display: block; } }

.is-lg {
  display: none; }
  @media (min-width: 992px) {
    .is-lg {
      display: block; } }

.is-xl {
  display: none; }
  @media (min-width: 1200px) {
    .is-xl {
      display: block; } }

.is-xxl {
  display: none; }
  @media (min-width: 1400px) {
    .is-xxl {
      display: block; } }

@keyframes animateLoaded__ {
  0% {
    opacity: 0;
    filter: blur(10px); }
  100% {
    opacity: 1;
    filter: blur(0); } }

[data-lazy-type].loaded {
  animation: animateLoaded__ 0.1s ease-out forwards; }

.after-header {
  padding-top: 14.3rem; }

hr {
  display: none; }

#sidebar {
  display: none; }
  @media (min-width: 992px) {
    #sidebar {
      position: fixed;
      top: 320px;
      right: 0;
      z-index: 10;
      display: flex;
      transform-origin: 85% 85%;
      transform: rotate(90deg) scale(0.9); } }
  @media (min-width: 1200px) {
    #sidebar {
      top: 400px; } }
  #sidebar > * {
    height: 40px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    padding: 0 12px;
    color: #fff;
    transition: all ease-out 200ms;
    text-decoration: none !important; }
    #sidebar > *:hover {
      text-decoration: none;
      transform: translate3d(0, 10px, 0); }
  #sidebar .mail {
    background: #8bbe1c;
    font-weight: 400;
    font-size: 16px; }
    #sidebar .mail:before {
      color: #fff;
      content: "\e90e";
      font-size: 17px;
      margin-right: 10px; }
  #sidebar .fb {
    font-size: 0;
    background-color: #3b5998; }
    #sidebar .fb:before {
      content: "\e906";
      color: #fff;
      font-size: 17px; }
  #sidebar .in {
    font-size: 0;
    background-color: #0d77b7; }
    #sidebar .in:before {
      content: "\e90f";
      color: #fff;
      font-size: 17px; }

.narrow-text {
  max-width: 89rem;
  margin: 0 auto; }

.center-content {
  text-align: center; }

.grey {
  background-color: #f4f6f8; }

.blue {
  background-color: #274c7f;
  color: #fff; }
  .blue h1, .blue h2, .blue h3, .blue h4 {
    color: #fff; }

.mb-220 {
  margin-bottom: 10rem; }

.mt-130 {
  margin-top: 13rem; }

.logos {
  display: flex;
  align-items: center;
  padding: 4.5rem 0 10rem;
  flex-wrap: wrap; }
  .logos .logo {
    padding: 2rem;
    text-align: center;
    width: 50%; }
    @media (min-width: 992px) {
      .logos .logo {
        width: 25%; } }
    .logos .logo img {
      filter: grayscale(100%);
      opacity: 0.5; }

.narrow {
  max-width: 88rem;
  margin-left: auto;
  margin-right: auto; }

.info-icons {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  .info-icons .info-icon {
    width: 100%;
    display: flex;
    margin-bottom: 5rem; }
    @media (min-width: 992px) {
      .info-icons .info-icon {
        width: calc(50% - 2.5rem); } }
    .info-icons .info-icon .left {
      margin-right: 4rem;
      width: 19.2rem;
      flex-shrink: 0; }
      .info-icons .info-icon .left img {
        width: 19.2rem; }
    .info-icons .info-icon .right {
      flex-shrink: 1; }
      @media (min-width: 992px) {
        .info-icons .info-icon .right {
          max-width: 40rem; } }
    .info-icons .info-icon h3 {
      margin: 5rem 0 2rem; }

.pager ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
  text-align: center; }
  .pager ul li {
    display: inline-block;
    padding: 15px; }
    .pager ul li:before {
      display: none; }

@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon/icomoon.eot?8xvtkc");
  src: url("../fonts/icomoon/icomoon.eot?8xvtkc#iefix") format("embedded-opentype"), url("../fonts/icomoon/icomoon.woff2?8xvtkc") format("woff2"), url("../fonts/icomoon/icomoon.ttf?8xvtkc") format("truetype"), url("../fonts/icomoon/icomoon.woff?8xvtkc") format("woff"), url("../fonts/icomoon/icomoon.svg?8xvtkc#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

.cms-bootstrap [class^="icon-"]:before, .cms-bootstrap [class*=" icon-"]:before, .ktc-admin-ui [class^=icon-]:before {
  font-family: 'Core-icons' !important; }

*:before,
*:after {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.helper-4k {
  max-width: 200rem;
  margin: auto; }

.hide {
  display: none !important; }

.show {
  display: block !important; }

.text-center {
  text-align: center; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.bold {
  font-weight: bold; }

.underline {
  text-decoration: underline; }

.line-through {
  text-decoration: line-through; }

html {
  font-size: 5px; }
  @media (min-width: 480px) {
    html {
      font-size: 5.5px; } }
  @media (min-width: 545px) {
    html {
      font-size: 6px; } }
  @media (min-width: 768px) {
    html {
      font-size: 7.5px; } }
  @media (min-width: 992px) {
    html {
      font-size: 8px; } }
  @media (min-width: 1200px) {
    html {
      font-size: 9px; } }
  @media (min-width: 1400px) {
    html {
      font-size: 10px; } }

@media only screen and (-webkit-min-device-pixel-ratio: 1.25), only screen and (-o-min-device-pixel-ratio: 5 / 4), only screen and (min-resolution: 120dpi), only screen and (min-resolution: 1.25dppx) {
  html {
    font-size: 5px; } }
  @media only screen and (-webkit-min-device-pixel-ratio: 1.25) and (min-width: 480px), only screen and (-o-min-device-pixel-ratio: 5 / 4) and (min-width: 480px), only screen and (min-resolution: 120dpi) and (min-width: 480px), only screen and (min-resolution: 1.25dppx) and (min-width: 480px) {
    html {
      font-size: 5.5px; } }
  @media only screen and (-webkit-min-device-pixel-ratio: 1.25) and (min-width: 545px), only screen and (-o-min-device-pixel-ratio: 5 / 4) and (min-width: 545px), only screen and (min-resolution: 120dpi) and (min-width: 545px), only screen and (min-resolution: 1.25dppx) and (min-width: 545px) {
    html {
      font-size: 6px; } }
  @media only screen and (-webkit-min-device-pixel-ratio: 1.25) and (min-width: 768px), only screen and (-o-min-device-pixel-ratio: 5 / 4) and (min-width: 768px), only screen and (min-resolution: 120dpi) and (min-width: 768px), only screen and (min-resolution: 1.25dppx) and (min-width: 768px) {
    html {
      font-size: 7.5px; } }
  @media only screen and (-webkit-min-device-pixel-ratio: 1.25) and (min-width: 992px), only screen and (-o-min-device-pixel-ratio: 5 / 4) and (min-width: 992px), only screen and (min-resolution: 120dpi) and (min-width: 992px), only screen and (min-resolution: 1.25dppx) and (min-width: 992px) {
    html {
      font-size: 8px; } }
  @media only screen and (-webkit-min-device-pixel-ratio: 1.25) and (min-width: 1200px), only screen and (-o-min-device-pixel-ratio: 5 / 4) and (min-width: 1200px), only screen and (min-resolution: 120dpi) and (min-width: 1200px), only screen and (min-resolution: 1.25dppx) and (min-width: 1200px) {
    html {
      font-size: 7.2px; } }
  @media only screen and (-webkit-min-device-pixel-ratio: 1.25) and (min-width: 1400px), only screen and (-o-min-device-pixel-ratio: 5 / 4) and (min-width: 1400px), only screen and (min-resolution: 120dpi) and (min-width: 1400px), only screen and (min-resolution: 1.25dppx) and (min-width: 1400px) {
    html {
      font-size: 8px; } }

form,
html,
body {
  font-family: "nimbus-sans", sans-serif;
  height: 100%;
  color: #2b2b2b; }

@media (min-width: 320px) {
  body {
    font-size: 2.8rem; } }

@media (min-width: 480px) {
  body {
    font-size: 2.54545rem; } }

@media (min-width: 545px) {
  body {
    font-size: 2.33333rem; } }

@media (min-width: 768px) {
  body {
    font-size: 1.86667rem; } }

@media (min-width: 992px) {
  body {
    font-size: 1.8rem; } }

img {
  max-width: 100%;
  height: auto !important; }

@media (min-width: 320px) {
  .pt-5 {
    padding-top: 4rem; } }

@media (min-width: 480px) {
  .pt-5 {
    padding-top: 3.63636rem; } }

@media (min-width: 545px) {
  .pt-5 {
    padding-top: 3.33333rem; } }

@media (min-width: 768px) {
  .pt-5 {
    padding-top: 2.66667rem; } }

@media (min-width: 992px) {
  .pt-5 {
    padding-top: 2.5rem; } }

@media (min-width: 1200px) {
  .pt-5 {
    padding-top: 2.22222rem; } }

@media (min-width: 1400px) {
  .pt-5 {
    padding-top: 2rem; } }

@media (min-width: 320px) {
  .pb-5 {
    padding-bottom: 4rem; } }

@media (min-width: 480px) {
  .pb-5 {
    padding-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .pb-5 {
    padding-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .pb-5 {
    padding-bottom: 2.66667rem; } }

@media (min-width: 992px) {
  .pb-5 {
    padding-bottom: 2.5rem; } }

@media (min-width: 1200px) {
  .pb-5 {
    padding-bottom: 2.22222rem; } }

@media (min-width: 1400px) {
  .pb-5 {
    padding-bottom: 2rem; } }

@media (min-width: 320px) {
  .mt-5 {
    margin-top: 4rem; } }

@media (min-width: 480px) {
  .mt-5 {
    margin-top: 3.63636rem; } }

@media (min-width: 545px) {
  .mt-5 {
    margin-top: 3.33333rem; } }

@media (min-width: 768px) {
  .mt-5 {
    margin-top: 2.66667rem; } }

@media (min-width: 992px) {
  .mt-5 {
    margin-top: 2.5rem; } }

@media (min-width: 1200px) {
  .mt-5 {
    margin-top: 2.22222rem; } }

@media (min-width: 1400px) {
  .mt-5 {
    margin-top: 2rem; } }

@media (min-width: 320px) {
  .mb-5 {
    margin-bottom: 4rem; } }

@media (min-width: 480px) {
  .mb-5 {
    margin-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .mb-5 {
    margin-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .mb-5 {
    margin-bottom: 2.66667rem; } }

@media (min-width: 992px) {
  .mb-5 {
    margin-bottom: 2.5rem; } }

@media (min-width: 1200px) {
  .mb-5 {
    margin-bottom: 2.22222rem; } }

@media (min-width: 1400px) {
  .mb-5 {
    margin-bottom: 2rem; } }

@media (min-width: 320px) {
  .pt-10 {
    padding-top: 4rem; } }

@media (min-width: 480px) {
  .pt-10 {
    padding-top: 3.63636rem; } }

@media (min-width: 545px) {
  .pt-10 {
    padding-top: 3.33333rem; } }

@media (min-width: 768px) {
  .pt-10 {
    padding-top: 2.66667rem; } }

@media (min-width: 992px) {
  .pt-10 {
    padding-top: 2.5rem; } }

@media (min-width: 1200px) {
  .pt-10 {
    padding-top: 2.22222rem; } }

@media (min-width: 1400px) {
  .pt-10 {
    padding-top: 2rem; } }

@media (min-width: 320px) {
  .pb-10 {
    padding-bottom: 4rem; } }

@media (min-width: 480px) {
  .pb-10 {
    padding-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .pb-10 {
    padding-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .pb-10 {
    padding-bottom: 2.66667rem; } }

@media (min-width: 992px) {
  .pb-10 {
    padding-bottom: 2.5rem; } }

@media (min-width: 1200px) {
  .pb-10 {
    padding-bottom: 2.22222rem; } }

@media (min-width: 1400px) {
  .pb-10 {
    padding-bottom: 2rem; } }

@media (min-width: 320px) {
  .mt-10 {
    margin-top: 4rem; } }

@media (min-width: 480px) {
  .mt-10 {
    margin-top: 3.63636rem; } }

@media (min-width: 545px) {
  .mt-10 {
    margin-top: 3.33333rem; } }

@media (min-width: 768px) {
  .mt-10 {
    margin-top: 2.66667rem; } }

@media (min-width: 992px) {
  .mt-10 {
    margin-top: 2.5rem; } }

@media (min-width: 1200px) {
  .mt-10 {
    margin-top: 2.22222rem; } }

@media (min-width: 1400px) {
  .mt-10 {
    margin-top: 2rem; } }

@media (min-width: 320px) {
  .mb-10 {
    margin-bottom: 4rem; } }

@media (min-width: 480px) {
  .mb-10 {
    margin-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .mb-10 {
    margin-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .mb-10 {
    margin-bottom: 2.66667rem; } }

@media (min-width: 992px) {
  .mb-10 {
    margin-bottom: 2.5rem; } }

@media (min-width: 1200px) {
  .mb-10 {
    margin-bottom: 2.22222rem; } }

@media (min-width: 1400px) {
  .mb-10 {
    margin-bottom: 2rem; } }

@media (min-width: 320px) {
  .pt-15 {
    padding-top: 4rem; } }

@media (min-width: 480px) {
  .pt-15 {
    padding-top: 3.63636rem; } }

@media (min-width: 545px) {
  .pt-15 {
    padding-top: 3.33333rem; } }

@media (min-width: 768px) {
  .pt-15 {
    padding-top: 2.66667rem; } }

@media (min-width: 992px) {
  .pt-15 {
    padding-top: 2.5rem; } }

@media (min-width: 1200px) {
  .pt-15 {
    padding-top: 2.22222rem; } }

@media (min-width: 1400px) {
  .pt-15 {
    padding-top: 2rem; } }

@media (min-width: 320px) {
  .pb-15 {
    padding-bottom: 4rem; } }

@media (min-width: 480px) {
  .pb-15 {
    padding-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .pb-15 {
    padding-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .pb-15 {
    padding-bottom: 2.66667rem; } }

@media (min-width: 992px) {
  .pb-15 {
    padding-bottom: 2.5rem; } }

@media (min-width: 1200px) {
  .pb-15 {
    padding-bottom: 2.22222rem; } }

@media (min-width: 1400px) {
  .pb-15 {
    padding-bottom: 2rem; } }

@media (min-width: 320px) {
  .mt-15 {
    margin-top: 4rem; } }

@media (min-width: 480px) {
  .mt-15 {
    margin-top: 3.63636rem; } }

@media (min-width: 545px) {
  .mt-15 {
    margin-top: 3.33333rem; } }

@media (min-width: 768px) {
  .mt-15 {
    margin-top: 2.66667rem; } }

@media (min-width: 992px) {
  .mt-15 {
    margin-top: 2.5rem; } }

@media (min-width: 1200px) {
  .mt-15 {
    margin-top: 2.22222rem; } }

@media (min-width: 1400px) {
  .mt-15 {
    margin-top: 2rem; } }

@media (min-width: 320px) {
  .mb-15 {
    margin-bottom: 4rem; } }

@media (min-width: 480px) {
  .mb-15 {
    margin-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .mb-15 {
    margin-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .mb-15 {
    margin-bottom: 2.66667rem; } }

@media (min-width: 992px) {
  .mb-15 {
    margin-bottom: 2.5rem; } }

@media (min-width: 1200px) {
  .mb-15 {
    margin-bottom: 2.22222rem; } }

@media (min-width: 1400px) {
  .mb-15 {
    margin-bottom: 2rem; } }

@media (min-width: 320px) {
  .pt-20 {
    padding-top: 4rem; } }

@media (min-width: 480px) {
  .pt-20 {
    padding-top: 3.63636rem; } }

@media (min-width: 545px) {
  .pt-20 {
    padding-top: 3.33333rem; } }

@media (min-width: 768px) {
  .pt-20 {
    padding-top: 2.66667rem; } }

@media (min-width: 992px) {
  .pt-20 {
    padding-top: 2.5rem; } }

@media (min-width: 1200px) {
  .pt-20 {
    padding-top: 2.22222rem; } }

@media (min-width: 1400px) {
  .pt-20 {
    padding-top: 2rem; } }

@media (min-width: 320px) {
  .pb-20 {
    padding-bottom: 4rem; } }

@media (min-width: 480px) {
  .pb-20 {
    padding-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .pb-20 {
    padding-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .pb-20 {
    padding-bottom: 2.66667rem; } }

@media (min-width: 992px) {
  .pb-20 {
    padding-bottom: 2.5rem; } }

@media (min-width: 1200px) {
  .pb-20 {
    padding-bottom: 2.22222rem; } }

@media (min-width: 1400px) {
  .pb-20 {
    padding-bottom: 2rem; } }

@media (min-width: 320px) {
  .mt-20 {
    margin-top: 4rem; } }

@media (min-width: 480px) {
  .mt-20 {
    margin-top: 3.63636rem; } }

@media (min-width: 545px) {
  .mt-20 {
    margin-top: 3.33333rem; } }

@media (min-width: 768px) {
  .mt-20 {
    margin-top: 2.66667rem; } }

@media (min-width: 992px) {
  .mt-20 {
    margin-top: 2.5rem; } }

@media (min-width: 1200px) {
  .mt-20 {
    margin-top: 2.22222rem; } }

@media (min-width: 1400px) {
  .mt-20 {
    margin-top: 2rem; } }

@media (min-width: 320px) {
  .mb-20 {
    margin-bottom: 4rem; } }

@media (min-width: 480px) {
  .mb-20 {
    margin-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .mb-20 {
    margin-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .mb-20 {
    margin-bottom: 2.66667rem; } }

@media (min-width: 992px) {
  .mb-20 {
    margin-bottom: 2.5rem; } }

@media (min-width: 1200px) {
  .mb-20 {
    margin-bottom: 2.22222rem; } }

@media (min-width: 1400px) {
  .mb-20 {
    margin-bottom: 2rem; } }

@media (min-width: 320px) {
  .pt-25 {
    padding-top: 4rem; } }

@media (min-width: 480px) {
  .pt-25 {
    padding-top: 3.63636rem; } }

@media (min-width: 545px) {
  .pt-25 {
    padding-top: 3.33333rem; } }

@media (min-width: 768px) {
  .pt-25 {
    padding-top: 2.66667rem; } }

@media (min-width: 992px) {
  .pt-25 {
    padding-top: 2.5rem; } }

@media (min-width: 320px) {
  .pb-25 {
    padding-bottom: 4rem; } }

@media (min-width: 480px) {
  .pb-25 {
    padding-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .pb-25 {
    padding-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .pb-25 {
    padding-bottom: 2.66667rem; } }

@media (min-width: 992px) {
  .pb-25 {
    padding-bottom: 2.5rem; } }

@media (min-width: 320px) {
  .mt-25 {
    margin-top: 4rem; } }

@media (min-width: 480px) {
  .mt-25 {
    margin-top: 3.63636rem; } }

@media (min-width: 545px) {
  .mt-25 {
    margin-top: 3.33333rem; } }

@media (min-width: 768px) {
  .mt-25 {
    margin-top: 2.66667rem; } }

@media (min-width: 992px) {
  .mt-25 {
    margin-top: 2.5rem; } }

@media (min-width: 320px) {
  .mb-25 {
    margin-bottom: 4rem; } }

@media (min-width: 480px) {
  .mb-25 {
    margin-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .mb-25 {
    margin-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .mb-25 {
    margin-bottom: 2.66667rem; } }

@media (min-width: 992px) {
  .mb-25 {
    margin-bottom: 2.5rem; } }

@media (min-width: 320px) {
  .pt-30 {
    padding-top: 4rem; } }

@media (min-width: 480px) {
  .pt-30 {
    padding-top: 3.63636rem; } }

@media (min-width: 545px) {
  .pt-30 {
    padding-top: 3.33333rem; } }

@media (min-width: 768px) {
  .pt-30 {
    padding-top: 3rem; } }

@media (min-width: 320px) {
  .pb-30 {
    padding-bottom: 4rem; } }

@media (min-width: 480px) {
  .pb-30 {
    padding-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .pb-30 {
    padding-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .pb-30 {
    padding-bottom: 3rem; } }

@media (min-width: 320px) {
  .mt-30 {
    margin-top: 4rem; } }

@media (min-width: 480px) {
  .mt-30 {
    margin-top: 3.63636rem; } }

@media (min-width: 545px) {
  .mt-30 {
    margin-top: 3.33333rem; } }

@media (min-width: 768px) {
  .mt-30 {
    margin-top: 3rem; } }

@media (min-width: 320px) {
  .mb-30 {
    margin-bottom: 4rem; } }

@media (min-width: 480px) {
  .mb-30 {
    margin-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .mb-30 {
    margin-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .mb-30 {
    margin-bottom: 3rem; } }

@media (min-width: 320px) {
  .pt-35 {
    padding-top: 4rem; } }

@media (min-width: 480px) {
  .pt-35 {
    padding-top: 3.63636rem; } }

@media (min-width: 545px) {
  .pt-35 {
    padding-top: 3.5rem; } }

@media (min-width: 320px) {
  .pb-35 {
    padding-bottom: 4rem; } }

@media (min-width: 480px) {
  .pb-35 {
    padding-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .pb-35 {
    padding-bottom: 3.5rem; } }

@media (min-width: 320px) {
  .mt-35 {
    margin-top: 4rem; } }

@media (min-width: 480px) {
  .mt-35 {
    margin-top: 3.63636rem; } }

@media (min-width: 545px) {
  .mt-35 {
    margin-top: 3.5rem; } }

@media (min-width: 320px) {
  .mb-35 {
    margin-bottom: 4rem; } }

@media (min-width: 480px) {
  .mb-35 {
    margin-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .mb-35 {
    margin-bottom: 3.5rem; } }

@media (min-width: 320px) {
  .pt-40 {
    padding-top: 4rem; } }

@media (min-width: 320px) {
  .pb-40 {
    padding-bottom: 4rem; } }

@media (min-width: 320px) {
  .mt-40 {
    margin-top: 4rem; } }

@media (min-width: 320px) {
  .mb-40 {
    margin-bottom: 4rem; } }

@media (min-width: 320px) {
  .pt-45 {
    padding-top: 4.5rem; } }

@media (min-width: 320px) {
  .pb-45 {
    padding-bottom: 4.5rem; } }

@media (min-width: 320px) {
  .mt-45 {
    margin-top: 4.5rem; } }

@media (min-width: 320px) {
  .mb-45 {
    margin-bottom: 4.5rem; } }

@media (min-width: 320px) {
  .pt-50 {
    padding-top: 5rem; } }

@media (min-width: 320px) {
  .pb-50 {
    padding-bottom: 5rem; } }

@media (min-width: 320px) {
  .mt-50 {
    margin-top: 5rem; } }

@media (min-width: 320px) {
  .mb-50 {
    margin-bottom: 5rem; } }

@media (min-width: 320px) {
  .pt-55 {
    padding-top: 5.5rem; } }

@media (min-width: 320px) {
  .pb-55 {
    padding-bottom: 5.5rem; } }

@media (min-width: 320px) {
  .mt-55 {
    margin-top: 5.5rem; } }

@media (min-width: 320px) {
  .mb-55 {
    margin-bottom: 5.5rem; } }

@media (min-width: 320px) {
  .pt-60 {
    padding-top: 6rem; } }

@media (min-width: 320px) {
  .pb-60 {
    padding-bottom: 6rem; } }

@media (min-width: 320px) {
  .mt-60 {
    margin-top: 6rem; } }

@media (min-width: 320px) {
  .mb-60 {
    margin-bottom: 6rem; } }

@media (min-width: 320px) {
  .pt-65 {
    padding-top: 6.5rem; } }

@media (min-width: 320px) {
  .pb-65 {
    padding-bottom: 6.5rem; } }

@media (min-width: 320px) {
  .mt-65 {
    margin-top: 6.5rem; } }

@media (min-width: 320px) {
  .mb-65 {
    margin-bottom: 6.5rem; } }

@media (min-width: 320px) {
  .pt-70 {
    padding-top: 7rem; } }

@media (min-width: 320px) {
  .pb-70 {
    padding-bottom: 7rem; } }

@media (min-width: 320px) {
  .mt-70 {
    margin-top: 7rem; } }

@media (min-width: 320px) {
  .mb-70 {
    margin-bottom: 7rem; } }

@media (min-width: 320px) {
  .pt-75 {
    padding-top: 7.5rem; } }

@media (min-width: 320px) {
  .pb-75 {
    padding-bottom: 7.5rem; } }

@media (min-width: 320px) {
  .mt-75 {
    margin-top: 7.5rem; } }

@media (min-width: 320px) {
  .mb-75 {
    margin-bottom: 7.5rem; } }

@media (min-width: 320px) {
  .pt-80 {
    padding-top: 8rem; } }

@media (min-width: 320px) {
  .pb-80 {
    padding-bottom: 8rem; } }

@media (min-width: 320px) {
  .mt-80 {
    margin-top: 8rem; } }

@media (min-width: 320px) {
  .mb-80 {
    margin-bottom: 8rem; } }

@media (min-width: 320px) {
  .pt-85 {
    padding-top: 8.5rem; } }

@media (min-width: 320px) {
  .pb-85 {
    padding-bottom: 8.5rem; } }

@media (min-width: 320px) {
  .mt-85 {
    margin-top: 8.5rem; } }

@media (min-width: 320px) {
  .mb-85 {
    margin-bottom: 8.5rem; } }

@media (min-width: 320px) {
  .pt-90 {
    padding-top: 9rem; } }

@media (min-width: 320px) {
  .pb-90 {
    padding-bottom: 9rem; } }

@media (min-width: 320px) {
  .mt-90 {
    margin-top: 9rem; } }

@media (min-width: 320px) {
  .mb-90 {
    margin-bottom: 9rem; } }

@media (min-width: 320px) {
  .pt-95 {
    padding-top: 9.5rem; } }

@media (min-width: 320px) {
  .pb-95 {
    padding-bottom: 9.5rem; } }

@media (min-width: 320px) {
  .mt-95 {
    margin-top: 9.5rem; } }

@media (min-width: 320px) {
  .mb-95 {
    margin-bottom: 9.5rem; } }

@media (min-width: 320px) {
  .pt-100 {
    padding-top: 10rem; } }

@media (min-width: 320px) {
  .pb-100 {
    padding-bottom: 10rem; } }

@media (min-width: 320px) {
  .mt-100 {
    margin-top: 10rem; } }

@media (min-width: 320px) {
  .mb-100 {
    margin-bottom: 10rem; } }

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4 {
  color: #274c7f;
  font-weight: bold; }

@media (min-width: 320px) {
  h1,
  .h1 {
    font-size: 6rem; } }

@media (min-width: 480px) {
  h1,
  .h1 {
    font-size: 5.5rem; } }

@media (min-width: 320px) {
  h1,
  .h1 {
    line-height: 6.8rem; } }

h2,
.h2 {
  margin: 5rem 0 2rem; }
  @media (min-width: 320px) {
    h2,
    .h2 {
      font-size: 5rem; } }
  @media (min-width: 480px) {
    h2,
    .h2 {
      font-size: 4.54545rem; } }
  @media (min-width: 545px) {
    h2,
    .h2 {
      font-size: 4.16667rem; } }
  @media (min-width: 768px) {
    h2,
    .h2 {
      font-size: 4rem; } }
  @media (min-width: 320px) {
    h2,
    .h2 {
      line-height: 5rem; } }
  @media (min-width: 480px) {
    h2,
    .h2 {
      line-height: 4.54545rem; } }
  @media (min-width: 545px) {
    h2,
    .h2 {
      line-height: 4.16667rem; } }
  @media (min-width: 768px) {
    h2,
    .h2 {
      line-height: 4rem; } }

@media (min-width: 320px) {
  h3,
  .h3 {
    font-size: 4rem; } }

@media (min-width: 480px) {
  h3,
  .h3 {
    font-size: 3.63636rem; } }

@media (min-width: 545px) {
  h3,
  .h3 {
    font-size: 3.33333rem; } }

@media (min-width: 768px) {
  h3,
  .h3 {
    font-size: 2.66667rem; } }

@media (min-width: 992px) {
  h3,
  .h3 {
    font-size: 2.5rem; } }

@media (min-width: 320px) {
  h3,
  .h3 {
    line-height: 4rem; } }

@media (min-width: 480px) {
  h3,
  .h3 {
    line-height: 3.63636rem; } }

@media (min-width: 545px) {
  h3,
  .h3 {
    line-height: 3.33333rem; } }

@media (min-width: 768px) {
  h3,
  .h3 {
    line-height: 3rem; } }

@media (min-width: 320px) {
  h4,
  .h4 {
    font-size: 3.6rem; } }

@media (min-width: 480px) {
  h4,
  .h4 {
    font-size: 3.27273rem; } }

@media (min-width: 545px) {
  h4,
  .h4 {
    font-size: 3rem; } }

@media (min-width: 768px) {
  h4,
  .h4 {
    font-size: 2.4rem; } }

@media (min-width: 992px) {
  h4,
  .h4 {
    font-size: 2.25rem; } }

@media (min-width: 1200px) {
  h4,
  .h4 {
    font-size: 2rem; } }

@media (min-width: 320px) {
  h4,
  .h4 {
    line-height: 3.6rem; } }

@media (min-width: 480px) {
  h4,
  .h4 {
    line-height: 3.27273rem; } }

@media (min-width: 545px) {
  h4,
  .h4 {
    line-height: 3rem; } }

@media (min-width: 768px) {
  h4,
  .h4 {
    line-height: 2.5rem; } }

@media (min-width: 320px) {
  big,
  .perex,
  .header-block p {
    font-size: 3.6rem; } }

@media (min-width: 480px) {
  big,
  .perex,
  .header-block p {
    font-size: 3.27273rem; } }

@media (min-width: 545px) {
  big,
  .perex,
  .header-block p {
    font-size: 3rem; } }

@media (min-width: 768px) {
  big,
  .perex,
  .header-block p {
    font-size: 2.4rem; } }

@media (min-width: 992px) {
  big,
  .perex,
  .header-block p {
    font-size: 2.25rem; } }

@media (min-width: 1200px) {
  big,
  .perex,
  .header-block p {
    font-size: 2rem; } }

@media (min-width: 320px) {
  big,
  .perex,
  .header-block p {
    line-height: 4.8rem; } }

@media (min-width: 480px) {
  big,
  .perex,
  .header-block p {
    line-height: 4.36364rem; } }

@media (min-width: 545px) {
  big,
  .perex,
  .header-block p {
    line-height: 4rem; } }

@media (min-width: 768px) {
  big,
  .perex,
  .header-block p {
    line-height: 3.2rem; } }

@media (min-width: 992px) {
  big,
  .perex,
  .header-block p {
    line-height: 3rem; } }

@media (min-width: 1200px) {
  big,
  .perex,
  .header-block p {
    line-height: 2.8rem; } }

@media (min-width: 320px) {
  small,
  .text-small {
    font-size: 2.8rem; } }

@media (min-width: 480px) {
  small,
  .text-small {
    font-size: 2.54545rem; } }

@media (min-width: 545px) {
  small,
  .text-small {
    font-size: 2.33333rem; } }

@media (min-width: 768px) {
  small,
  .text-small {
    font-size: 1.86667rem; } }

@media (min-width: 992px) {
  small,
  .text-small {
    font-size: 1.75rem; } }

@media (min-width: 1200px) {
  small,
  .text-small {
    font-size: 1.55556rem; } }

@media (min-width: 1400px) {
  small,
  .text-small {
    font-size: 1.4rem; } }

@media (min-width: 320px) {
  small,
  .text-small {
    line-height: 3.6rem; } }

@media (min-width: 480px) {
  small,
  .text-small {
    line-height: 3.27273rem; } }

@media (min-width: 545px) {
  small,
  .text-small {
    line-height: 3rem; } }

@media (min-width: 768px) {
  small,
  .text-small {
    line-height: 2.4rem; } }

@media (min-width: 992px) {
  small,
  .text-small {
    line-height: 2.25rem; } }

@media (min-width: 1200px) {
  small,
  .text-small {
    line-height: 2rem; } }

@media (min-width: 1400px) {
  small,
  .text-small {
    line-height: 1.8rem; } }

a {
  color: #8bbe1c;
  font-weight: bold;
  transition: all ease-in 200ms;
  cursor: pointer; }
  a:focus, a:hover {
    color: #8bbe1c;
    outline: none;
    text-decoration: underline; }
  a.link-blue {
    color: #274c7f;
    text-decoration: underline;
    font-weight: 400; }
    a.link-blue:focus, a.link-blue:hover {
      text-decoration: none; }

.blue-links a {
  color: #274c7f;
  text-decoration: underline;
  font-weight: 400; }
  .blue-links a:focus, .blue-links a:hover {
    text-decoration: none; }

.btn {
  display: inline-block;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  border: none;
  padding: 2rem 6rem;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  text-transform: none;
  font-weight: bold;
  white-space: initial; }
  @media (min-width: 320px) {
    .btn {
      font-size: 3.6rem; } }
  @media (min-width: 480px) {
    .btn {
      font-size: 3.27273rem; } }
  @media (min-width: 545px) {
    .btn {
      font-size: 3rem; } }
  @media (min-width: 768px) {
    .btn {
      font-size: 2.4rem; } }
  @media (min-width: 992px) {
    .btn {
      font-size: 2.25rem; } }
  @media (min-width: 1200px) {
    .btn {
      font-size: 2rem; } }
  .btn:focus, .btn:hover {
    text-decoration: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none; }

.btn-primary {
  background: #8bbe1c;
  color: #fff; }
  .btn-primary:focus, .btn-primary:hover {
    text-decoration: none;
    background: #7ba819;
    color: #fff; }

.btn-default {
  background: transparent;
  color: #274c7f;
  border: 2px solid #274c7f; }
  .btn-default:focus, .btn-default:hover {
    text-decoration: none;
    background: #274c7f;
    color: #fff; }

.header-block .btn {
  font-size: 3.2rem; }
  @media (min-width: 480px) {
    .header-block .btn {
      font-size: 2.90909rem; } }
  @media (min-width: 545px) {
    .header-block .btn {
      font-size: 2.66667rem; } }
  @media (min-width: 768px) {
    .header-block .btn {
      font-size: 2.13333rem; } }
  @media (min-width: 992px) {
    .header-block .btn {
      font-size: 2rem; } }
  @media (min-width: 1200px) {
    .header-block .btn {
      font-size: 1.77778rem; } }
  @media (min-width: 1400px) {
    .header-block .btn {
      font-size: 1.6rem; } }

.image-top-center {
  position: top center !important; }

.image-top-left {
  position: top left !important; }

.image-top-right {
  position: top left !important; }

.image-bottom-center {
  position: bottom center !important; }

.image-bottom-left {
  position: bottom left !important; }

.image-bottom-right {
  position: bottom right !important; }

main {
  min-height: 40rem; }
  main ol {
    margin: 0;
    padding: 0;
    list-style: none;
    counter-reset: my-ol; }
    main ol li {
      position: relative;
      margin: 0 0 1.5rem 0;
      padding: 0 0 0 3rem; }
      main ol li:before {
        content: counter(my-ol) ".";
        counter-increment: my-ol;
        color: #274c7f;
        position: absolute;
        left: 0;
        top: 3px;
        font-weight: bold;
        font-family: "nimbus-sans", sans-serif !important; }
  main ul {
    margin: 0;
    padding: 0;
    list-style: none; }
    main ul li {
      margin: 0 0 1.5rem 0;
      padding: 0 0 0 3rem;
      position: relative; }
      main ul li:before {
        content: "";
        width: 6px;
        height: 6px;
        background: #274c7f;
        position: absolute;
        top: 6px;
        left: 0;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        border-radius: 50%; }
  main table {
    width: 100%; }
    main table tr {
      border-bottom: 1px solid transparent; }
      main table tr td {
        padding: 1rem 1.5rem; }
      main table tr:nth-child(even) {
        background: #f4f6f8; }
  main .respo-table table {
    width: 100%; }
    main .respo-table table thead {
      display: none; }
      @media (min-width: 768px) {
        main .respo-table table thead {
          display: table-header-group; } }
      main .respo-table table thead tr {
        border-bottom: 1px solid transparent; }
        main .respo-table table thead tr th {
          font-weight: bold;
          padding: 1rem 1.5rem; }
    main .respo-table table tbody tr {
      display: block;
      border: 1px solid transparent;
      border-bottom: none;
      margin: 0 0 2rem 0; }
      main .respo-table table tbody tr:nth-child(even) {
        background: #f4f6f8; }
      @media (min-width: 768px) {
        main .respo-table table tbody tr {
          display: table-row;
          margin: 0;
          border: none;
          border-bottom: 1px solid transparent; } }
      main .respo-table table tbody tr td {
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: space-between;
        -moz-box-pack: space-between;
        -ms-flex-pack: space-between;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        width: 100%;
        border-bottom: 1px solid transparent; }
        @media (min-width: 768px) {
          main .respo-table table tbody tr td {
            display: table-cell;
            border: none;
            width: auto; } }
        main .respo-table table tbody tr td:before {
          content: attr(data-th);
          display: block;
          text-align: left;
          font-family: "nimbus-sans", sans-serif !important;
          margin: 0 1.5rem 0 0; }
          @media (min-width: 768px) {
            main .respo-table table tbody tr td:before {
              display: none; } }

.label-form,
label {
  display: block;
  margin: 1rem 0 0.5rem 0;
  font-size: 2.8rem; }
  @media (min-width: 480px) {
    .label-form,
    label {
      font-size: 2.54545rem; } }
  @media (min-width: 545px) {
    .label-form,
    label {
      font-size: 2.33333rem; } }
  @media (min-width: 768px) {
    .label-form,
    label {
      font-size: 1.86667rem; } }
  @media (min-width: 992px) {
    .label-form,
    label {
      font-size: 1.75rem; } }
  @media (min-width: 1200px) {
    .label-form,
    label {
      font-size: 1.55556rem; } }
  @media (min-width: 1400px) {
    .label-form,
    label {
      font-size: 1.4rem; } }

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

input[type="number"] {
  -moz-appearance: textfield; }

.form-control,
select,
select.form-control,
textarea,
textarea.form-control,
input[type="text"],
input[type="number"],
input[type="email"],
input[type="password"] {
  width: 100%;
  padding: 0 1.5rem;
  border: 1px solid #e1e1e1;
  background: #fff;
  color: #2b2b2b;
  font-size: 2.8rem;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0; }
  @media (min-width: 320px) {
    .form-control,
    select,
    select.form-control,
    textarea,
    textarea.form-control,
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"] {
      height: 8rem; } }
  @media (min-width: 480px) {
    .form-control,
    select,
    select.form-control,
    textarea,
    textarea.form-control,
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"] {
      height: 7.27273rem; } }
  @media (min-width: 545px) {
    .form-control,
    select,
    select.form-control,
    textarea,
    textarea.form-control,
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"] {
      height: 6.66667rem; } }
  @media (min-width: 768px) {
    .form-control,
    select,
    select.form-control,
    textarea,
    textarea.form-control,
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"] {
      height: 5.33333rem; } }
  @media (min-width: 992px) {
    .form-control,
    select,
    select.form-control,
    textarea,
    textarea.form-control,
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"] {
      height: 5rem; } }
  @media (min-width: 480px) {
    .form-control,
    select,
    select.form-control,
    textarea,
    textarea.form-control,
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"] {
      font-size: 2.54545rem; } }
  @media (min-width: 545px) {
    .form-control,
    select,
    select.form-control,
    textarea,
    textarea.form-control,
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"] {
      font-size: 2.33333rem; } }
  @media (min-width: 768px) {
    .form-control,
    select,
    select.form-control,
    textarea,
    textarea.form-control,
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"] {
      font-size: 1.86667rem; } }
  @media (min-width: 992px) {
    .form-control,
    select,
    select.form-control,
    textarea,
    textarea.form-control,
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"] {
      font-size: 1.75rem; } }
  @media (min-width: 1200px) {
    .form-control,
    select,
    select.form-control,
    textarea,
    textarea.form-control,
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"] {
      font-size: 1.55556rem; } }
  @media (min-width: 1400px) {
    .form-control,
    select,
    select.form-control,
    textarea,
    textarea.form-control,
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"] {
      font-size: 1.4rem; } }
  .form-control::-webkit-input-placeholder,
  select::-webkit-input-placeholder,
  select.form-control::-webkit-input-placeholder,
  textarea::-webkit-input-placeholder,
  textarea.form-control::-webkit-input-placeholder,
  input[type="text"]::-webkit-input-placeholder,
  input[type="number"]::-webkit-input-placeholder,
  input[type="email"]::-webkit-input-placeholder,
  input[type="password"]::-webkit-input-placeholder {
    color: #000; }
  .form-control:-moz-placeholder,
  select:-moz-placeholder,
  select.form-control:-moz-placeholder,
  textarea:-moz-placeholder,
  textarea.form-control:-moz-placeholder,
  input[type="text"]:-moz-placeholder,
  input[type="number"]:-moz-placeholder,
  input[type="email"]:-moz-placeholder,
  input[type="password"]:-moz-placeholder {
    color: #000; }
  .form-control::-moz-placeholder,
  select::-moz-placeholder,
  select.form-control::-moz-placeholder,
  textarea::-moz-placeholder,
  textarea.form-control::-moz-placeholder,
  input[type="text"]::-moz-placeholder,
  input[type="number"]::-moz-placeholder,
  input[type="email"]::-moz-placeholder,
  input[type="password"]::-moz-placeholder {
    color: #000; }
  .form-control:-ms-input-placeholder,
  select:-ms-input-placeholder,
  select.form-control:-ms-input-placeholder,
  textarea:-ms-input-placeholder,
  textarea.form-control:-ms-input-placeholder,
  input[type="text"]:-ms-input-placeholder,
  input[type="number"]:-ms-input-placeholder,
  input[type="email"]:-ms-input-placeholder,
  input[type="password"]:-ms-input-placeholder {
    color: #000; }
  .form-control:focus,
  select:focus,
  select.form-control:focus,
  textarea:focus,
  textarea.form-control:focus,
  input[type="text"]:focus,
  input[type="number"]:focus,
  input[type="email"]:focus,
  input[type="password"]:focus {
    outline: 0;
    border: 1px solid #8bbe1c; }

textarea.form-control,
textarea {
  padding: 1.5rem; }
  @media (min-width: 320px) {
    textarea.form-control,
    textarea {
      height: 20rem; } }
  @media (min-width: 480px) {
    textarea.form-control,
    textarea {
      height: 18.18182rem; } }
  @media (min-width: 545px) {
    textarea.form-control,
    textarea {
      height: 16.66667rem; } }
  @media (min-width: 768px) {
    textarea.form-control,
    textarea {
      height: 15rem; } }
  textarea.form-control:focus,
  textarea:focus {
    border: 1px solid #8bbe1c; }

input[type="submit"] {
  cursor: pointer; }

.required label:before {
  content: "*";
  display: inline-block;
  vertical-align: 0.6rem;
  margin: 0 2px 0 0;
  font-size: 2rem; }
  @media (min-width: 480px) {
    .required label:before {
      font-size: 1.81818rem; } }
  @media (min-width: 545px) {
    .required label:before {
      font-size: 1.66667rem; } }
  @media (min-width: 768px) {
    .required label:before {
      font-size: 1.33333rem; } }
  @media (min-width: 992px) {
    .required label:before {
      font-size: 1.25rem; } }
  @media (min-width: 1200px) {
    .required label:before {
      font-size: 1.11111rem; } }
  @media (min-width: 1400px) {
    .required label:before {
      font-size: 1rem; } }

label.required:after {
  content: "*";
  display: inline-block;
  vertical-align: 0.6rem;
  margin: 0 2px 0 0;
  color: #274c7f;
  font-size: 2rem; }
  @media (min-width: 480px) {
    label.required:after {
      font-size: 1.81818rem; } }
  @media (min-width: 545px) {
    label.required:after {
      font-size: 1.66667rem; } }
  @media (min-width: 768px) {
    label.required:after {
      font-size: 1.33333rem; } }
  @media (min-width: 992px) {
    label.required:after {
      font-size: 1.25rem; } }
  @media (min-width: 1200px) {
    label.required:after {
      font-size: 1.11111rem; } }
  @media (min-width: 1400px) {
    label.required:after {
      font-size: 1rem; } }

.file .uploader-upload {
  display: none; }

.file label {
  display: inline-block;
  padding: 1rem 2rem;
  text-transform: uppercase;
  color: #fff;
  background: #274c7f;
  cursor: pointer;
  font-size: 2.8rem;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  max-width: 24rem;
  margin: 0;
  width: 100%;
  text-align: center;
  cursor: pointer;
  letter-spacing: 1px;
  outline: 0 !important; }
  @media (min-width: 480px) {
    .file label {
      font-size: 2.54545rem; } }
  @media (min-width: 545px) {
    .file label {
      font-size: 2.33333rem; } }
  @media (min-width: 768px) {
    .file label {
      font-size: 1.86667rem; } }
  @media (min-width: 992px) {
    .file label {
      font-size: 1.75rem; } }
  @media (min-width: 1200px) {
    .file label {
      font-size: 1.55556rem; } }
  @media (min-width: 1400px) {
    .file label {
      font-size: 1.4rem; } }

.file .file-name {
  padding: 0.5rem;
  background: #f4f6f8;
  color: #2b2b2b; }

.form-validation-error select,
.form-validation-error .selectric,
.form-validation-error input[type="text"],
.form-validation-error input[type="password"],
.form-validation-error input[type="email"],
.form-validation-error input[type="number"],
.form-validation-error textarea,
.Error:not(.form-validation-valid) select,
.Error:not(.form-validation-valid) .selectric,
.Error:not(.form-validation-valid) input[type="text"],
.Error:not(.form-validation-valid) input[type="password"],
.Error:not(.form-validation-valid) input[type="email"],
.Error:not(.form-validation-valid) input[type="number"],
.Error:not(.form-validation-valid) textarea {
  border: 1px solid #d40511 !important;
  -webkit-box-shadow: inset 0 0 0 1px #d40511;
  -moz-box-shadow: inset 0 0 0 1px #d40511;
  box-shadow: inset 0 0 0 1px #d40511;
  -webkit-animation-name: blink;
  -webkit-animation-duration: 1s;
  -webkit-animation-timing-function: ease;
  -webkit-animation-iteration-count: 2;
  -webkit-animation-direction: normal;
  -moz-animation-name: blink;
  -moz-animation-duration: 1s;
  -moz-animation-timing-function: ease;
  -moz-animation-iteration-count: 2;
  -moz-animation-direction: normal;
  -o-animation-name: blink;
  -o-animation-duration: 1s;
  -o-animation-timing-function: ease;
  -o-animation-iteration-count: 2;
  -o-animation-direction: normal;
  animation-name: blink;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-iteration-count: 2;
  animation-direction: normal; }

@-webkit-keyframes blink {
  0% {
    background: #fff; }
  50% {
    background: #fec7ca; }
  100% {
    background: #fff; } }

@-moz-keyframes blink {
  0% {
    background: #fff; }
  50% {
    background: #fec7ca; }
  100% {
    background: #fff; } }

@-ms-keyframes blink {
  0% {
    background: #fff; }
  50% {
    background: #fec7ca; }
  100% {
    background: #fff; } }

@keyframes blink {
  0% {
    background: #fff; }
  50% {
    background: #fec7ca; }
  100% {
    background: #fff; } }

div.form-validation-valid .FormErrorLabel,
div.form-validation-valid .EditingFormErrorLabel {
  display: none; }

.ErrorLabel,
.form-control-error,
div.form-validation-error,
.EditingFormErrorLabel {
  font-size: 2.8rem;
  color: #d40511;
  display: block;
  margin: 0.5rem 0 0 0; }
  @media (min-width: 480px) {
    .ErrorLabel,
    .form-control-error,
    div.form-validation-error,
    .EditingFormErrorLabel {
      font-size: 2.54545rem; } }
  @media (min-width: 545px) {
    .ErrorLabel,
    .form-control-error,
    div.form-validation-error,
    .EditingFormErrorLabel {
      font-size: 2.33333rem; } }
  @media (min-width: 768px) {
    .ErrorLabel,
    .form-control-error,
    div.form-validation-error,
    .EditingFormErrorLabel {
      font-size: 1.86667rem; } }
  @media (min-width: 992px) {
    .ErrorLabel,
    .form-control-error,
    div.form-validation-error,
    .EditingFormErrorLabel {
      font-size: 1.75rem; } }
  @media (min-width: 1200px) {
    .ErrorLabel,
    .form-control-error,
    div.form-validation-error,
    .EditingFormErrorLabel {
      font-size: 1.55556rem; } }
  @media (min-width: 1400px) {
    .ErrorLabel,
    .form-control-error,
    div.form-validation-error,
    .EditingFormErrorLabel {
      font-size: 1.4rem; } }

.radio input[type="radio"] {
  display: none; }
  .radio input[type="radio"] + label {
    position: relative;
    padding: 0 0 1rem 35px;
    display: inline-block;
    margin: 0; }
    .radio input[type="radio"] + label:before {
      content: "";
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      background: #fff;
      display: block;
      position: absolute;
      left: 0;
      top: 1px;
      border: 1px solid #e1e1e1;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
    .radio input[type="radio"] + label:after {
      content: "";
      display: block;
      left: 5px;
      top: 6px;
      width: 10px;
      height: 10px;
      background: #8bbe1c;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      border-radius: 50%;
      position: absolute;
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
  .radio input[type="radio"]:checked + label:before {
    border: 1px solid #e1e1e1;
    background: #f4f6f8; }
  .radio input[type="radio"]:checked + label:after {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100); }

.checkbox input[type="checkbox"] {
  display: none; }
  .checkbox input[type="checkbox"] + label {
    position: relative;
    padding: 0 0 1rem 35px;
    margin: 0;
    display: inline-block; }
    .checkbox input[type="checkbox"] + label:before {
      content: "";
      width: 20px;
      height: 20px;
      background: #fff;
      display: block;
      position: absolute;
      left: 0;
      top: 1px;
      border: 1px solid #e1e1e1;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
    .checkbox input[type="checkbox"] + label:after {
      content: "";
      display: block;
      left: 5px;
      top: 6px;
      width: 10px;
      height: 10px;
      background: #8bbe1c;
      position: absolute;
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
  .checkbox input[type="checkbox"]:checked + label:before {
    border: 1px solid #e1e1e1;
    background: #f4f6f8; }
  .checkbox input[type="checkbox"]:checked + label:after {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100); }

.row-form {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -1rem; }

.row-form [class^="col"] {
  padding-left: 1rem;
  padding-right: 1rem; }

.header-block {
  background-color: #fff;
  background-image: linear-gradient(-125deg, #fff 25%, #e7eaef 100%);
  padding: 20rem 0 9rem;
  position: relative;
  overflow: hidden; }
  @media (min-width: 992px) {
    .header-block {
      min-height: 72rem;
      display: flex;
      align-items: center; } }
  .header-block:before {
    content: "";
    width: 74rem;
    height: 74rem;
    background-color: #ffffff;
    border-radius: 50%;
    position: absolute;
    z-index: 0;
    top: 17.5rem;
    left: 45%; }
  .header-block-inner {
    display: flex;
    flex-wrap: wrap;
    position: relative; }
  .header-block-text {
    width: 100%; }
    @media (min-width: 992px) {
      .header-block-text {
        max-width: 57rem; } }
  .header-block-img {
    width: 100%; }
    .header-block-img img {
      margin-top: 4rem; }
      @media (min-width: 992px) {
        .header-block-img img {
          margin-top: 0;
          position: absolute;
          top: 50%;
          transform: translate3d(0, -50%, 0);
          max-width: 75%;
          right: 0; } }
    @media (min-width: 992px) {
      .header-block-img.hp-position-helper img {
        right: -4rem; } }
  @media (min-width: 992px) {
    .header-block.smaller-image .header-block-img img {
      max-width: 52%; } }
  @media (min-width: 1400px) {
    .header-block.smaller-image .header-block-img img {
      max-width: 60%; } }
  .header-block h1 {
    margin-bottom: 3rem; }
  .header-block big {
    display: block;
    margin-bottom: 2rem; }
  .header-block .btn {
    min-width: 25rem;
    margin: 9px 0;
    padding: 2rem 3rem; }
  .header-block .pux-container {
    position: relative;
    width: 100%; }
  .header-block.simple {
    min-height: 1rem;
    padding: 21rem 0 5rem; }
    .header-block.simple .header-block-text {
      width: 100%;
      max-width: 100%; }
    .header-block.simple h1 {
      margin: 0;
      text-align: center; }

.negative-bottom {
  margin-bottom: -4rem; }
  .negative-bottom .header-block {
    padding-bottom: 13rem; }

.page-builder .header-block {
  overflow: visible !important; }

@media (min-width: 992px) {
  .IE .header-block-img img {
    top: 190px; } }

.services {
  margin: 20rem 0 0; }
  @media (min-width: 768px) {
    .services {
      margin: 8rem 0 0; } }
  .services .top {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem; }
    @media (min-width: 768px) {
      .services .top {
        margin-bottom: 7rem; } }
  .services .service {
    position: relative;
    display: flex;
    flex-flow: wrap column;
    justify-content: center;
    align-items: center;
    padding: 5rem;
    min-height: 30rem;
    width: 50%; }
    @media (min-width: 768px) {
      .services .service {
        border-radius: 50%;
        background: rgba(39, 76, 127, 0.1);
        width: 46rem;
        height: 46rem; } }
    .services .service h3 {
      text-align: center;
      margin-top: 3rem;
      transition: all ease-in 250ms;
      max-width: 28rem; }
      @media (min-width: 320px) {
        .services .service h3 {
          font-size: 3.6rem; } }
      @media (min-width: 480px) {
        .services .service h3 {
          font-size: 3.27273rem; } }
      @media (min-width: 545px) {
        .services .service h3 {
          font-size: 3rem; } }
      @media (min-width: 320px) {
        .services .service h3 {
          line-height: 4rem; } }
      @media (min-width: 480px) {
        .services .service h3 {
          line-height: 3.63636rem; } }
      @media (min-width: 545px) {
        .services .service h3 {
          line-height: 3.6rem; } }
    @media (min-width: 768px) {
      .services .service:first-of-type {
        transform: translate3d(2.5rem, 0, 0); } }
    .services .service:first-of-type img {
      width: 21.5rem;
      height: 18.7rem; }
    @media (min-width: 768px) {
      .services .service:last-of-type {
        transform: translate3d(-2.5rem, 0, 0); } }
    .services .service:last-of-type img {
      width: 24.8rem;
      height: 18.4rem; }
    .services .service img {
      transition: all ease-in 250ms; }
    .services .service:hover {
      text-decoration: none; }
      .services .service:hover h3 {
        color: #8bbe1c; }
      .services .service:hover img {
        transform: scale(0.9); }
  .services .bottom {
    display: flex;
    flex-flow: row wrap; }
  .services .subservice {
    width: 50%;
    padding: 14rem 4.5rem 3rem;
    position: relative;
    text-align: center;
    margin-top: 8rem; }
    @media (min-width: 768px) {
      .services .subservice {
        width: 20%;
        margin-top: 0; } }
    .services .subservice:before {
      color: #2b4261;
      position: absolute;
      font-size: 9.5rem;
      top: 0;
      left: 50%;
      transform: translateX(-50%); }
    .services .subservice:after {
      content: "";
      position: absolute;
      width: 3px;
      height: 5rem;
      background: #8bbe1c;
      left: 50%;
      margin-left: -1px;
      top: -8rem; }
      @media (min-width: 768px) {
        .services .subservice:after {
          margin-left: 0;
          background-size: contain !important;
          background-repeat: no-repeat !important; } }
    .services .subservice:nth-of-type(1):before {
      content: "\e908"; }
    @media (min-width: 768px) {
      .services .subservice:nth-of-type(1):after {
        width: 16.5rem;
        height: 10.9rem;
        top: -13.5rem;
        left: 13rem;
        background: url("../img/service-line1.svg"); } }
    .services .subservice:nth-of-type(2):before {
      content: "\e90b"; }
    @media (min-width: 768px) {
      .services .subservice:nth-of-type(2):after {
        width: 3.8rem;
        height: 4rem;
        top: -5.5rem;
        left: 16rem;
        background: url("../img/service-line2.svg"); } }
    .services .subservice:nth-of-type(3):before {
      content: "\e90a"; }
    @media (min-width: 768px) {
      .services .subservice:nth-of-type(3):after {
        width: 3px;
        height: 13.5rem;
        top: -15.5rem;
        left: 50%;
        margin-left: -1px;
        background: #8bbe1c; } }
    .services .subservice:nth-of-type(4):before {
      content: "\e924"; }
    @media (min-width: 768px) {
      .services .subservice:nth-of-type(4):after {
        width: 3.3rem;
        height: 4.6rem;
        top: -6rem;
        left: 10rem;
        background: url("../img/service-line4.svg"); } }
    .services .subservice:nth-of-type(5):before {
      content: "\e925"; }
    @media (min-width: 768px) {
      .services .subservice:nth-of-type(5):after {
        width: 12.5rem;
        height: 13.8rem;
        top: -16rem;
        left: 1rem;
        background: url("../img/service-line5.svg"); } }

#service-more:before {
  content: "";
  position: absolute;
  display: inline-block;
  width: 179px;
  height: 65px;
  background: url("../img/sipka-text-mobil.svg");
  background-size: contain !important;
  background-repeat: no-repeat !important;
  top: -60px;
  left: 100%;
  margin-left: -89px; }
  @media (min-width: 768px) {
    #service-more:before {
      margin-left: 0;
      top: -37px;
      left: -40px;
      background: url("../img/sipka-text.svg");
      width: 260px;
      height: 93px; } }
  @media (min-width: 1200px) {
    #service-more:before {
      top: -14px;
      left: -152px; } }

.slider {
  padding: 4rem 0 4.5rem; }
  .slider .slide {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 5rem;
    outline: 0 !important; }
    @media (min-width: 992px) {
      .slider .slide {
        flex-wrap: nowrap; } }
    .slider .slide .left {
      background: #274c7f;
      padding: 6rem;
      color: #fff;
      transform: translate3d(-5rem, 0, 0);
      transition: all ease-out 200ms;
      width: 100%; }
      @media (min-width: 992px) {
        .slider .slide .left {
          padding: 6rem 16.5rem 6rem 7.5rem;
          width: calc(50% + 2.5rem);
          margin-right: -2.5rem; } }
    .slider .slide .right {
      background-size: cover !important;
      background-position: center center !important;
      transform: translate3d(5rem, 0, 0);
      transition: all ease-out 200ms;
      position: relative;
      width: 100%;
      padding-top: 66%; }
      @media (min-width: 992px) {
        .slider .slide .right {
          top: 5rem;
          width: calc(50% + 2.5rem);
          margin-left: -2.5rem;
          padding-top: 0; } }
      .slider .slide .right .video {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center; }
        .slider .slide .right .video:before {
          content: "\e910";
          color: #8bbe1c;
          font-size: 4.5rem;
          height: 8rem;
          width: 8rem;
          background: #fff;
          display: inline-block;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: all ease-in-out 200ms;
          opacity: 1;
          border-radius: 50%;
          padding-left: 1rem; }
        .slider .slide .right .video:hover {
          text-decoration: none; }
          .slider .slide .right .video:hover:before {
            opacity: 0.7; }
    .slider .slide h2 {
      margin: 0 0 3rem 0;
      color: #fff; }
    .slider .slide.slick-active .left, .slider .slide.slick-active .right {
      transition: all ease-out 800ms;
      transform: translate3d(0, 0, 0); }

.slick-arrow {
  font-size: 0;
  width: 5.5rem;
  height: 5.5rem;
  border: 0;
  background: #8bbe1c;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all ease-out 200ms;
  cursor: pointer;
  position: absolute;
  outline: 0 !important;
  z-index: 2;
  opacity: 1;
  transform: scale(1);
  top: 5.3rem; }
  @media (min-width: 992px) {
    .slick-arrow {
      top: initial;
      bottom: 10.9rem; } }
  .slick-arrow.slick-disabled {
    opacity: 0;
    transform: scale(0); }
  .slick-arrow:before {
    font-size: 2.3rem;
    color: #fff; }
  .slick-arrow:hover, .slick-arrow:focus {
    background: #7ba819; }
  .slick-arrow.slick-prev {
    left: 9.5rem; }
    @media (min-width: 992px) {
      .slick-arrow.slick-prev {
        left: 44.4rem; } }
    .slick-arrow.slick-prev:before {
      content: "\e902"; }
  .slick-arrow.slick-next {
    left: 17rem; }
    @media (min-width: 992px) {
      .slick-arrow.slick-next {
        left: 51.4rem; } }
    .slick-arrow.slick-next:before {
      content: "\e903"; }

.img-bg {
  position: relative;
  overflow: hidden; }
  .img-bg:before, .img-bg:after {
    content: "";
    width: 500px;
    height: 100%;
    position: absolute;
    z-index: 0;
    top: 50%;
    transform: translateY(-50%);
    background-repeat: no-repeat !important;
    background-position: center 100% !important; }
  .img-bg.grey:before, .img-bg.grey:after {
    background-position: center center !important; }
  .img-bg:before {
    background: url("../img/summary-left.png");
    left: -350px; }
    @media (min-width: 1400px) {
      .img-bg:before {
        left: 0; } }
  .img-bg:after {
    background: url("../img/summary-right.png");
    right: -350px; }
    @media (min-width: 1400px) {
      .img-bg:after {
        right: 0; } }

.summary {
  max-width: 100rem;
  margin: 5rem auto 0; }
  .summary-item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 2rem; }
    .summary-item-text, .summary-item-img {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-flow: column;
      padding-left: 2rem;
      padding-right: 2rem; }
      @media (min-width: 768px) {
        .summary-item-text, .summary-item-img {
          width: 50%; } }
    .summary-item-text {
      order: 4;
      text-align: center; }
      @media (min-width: 768px) {
        .summary-item-text {
          text-align: left; } }
      .summary-item-text .text {
        max-width: 100%; }
    .summary-item-img {
      padding: 4rem 6rem;
      order: 3; }
    @media (min-width: 768px) {
      .summary-item:nth-of-type(even) .summary-item-img {
        order: 5; } }
  .summary h3 {
    margin-bottom: 1rem;
    width: 100%; }

.cta-block {
  background-size: cover !important;
  padding: 7rem 5rem 8rem;
  margin: 7.5rem 0 9rem;
  text-align: center;
  position: relative; }
  .cta-block h2 {
    color: #fff;
    max-width: 85rem;
    margin: 0 auto; }
  .cta-block .btn {
    position: absolute;
    bottom: 0;
    transform: translate3d(-50%, 50%, 0); }

.IE .cta-block .btn {
  left: 50%; }

.history-wrap {
  border-top: 3px solid #f4f6f8;
  margin-top: 9rem; }

.history {
  top: -20px;
  position: relative;
  max-width: 70rem;
  margin: 0 auto; }
  .history-item {
    padding: 7rem 0;
    position: relative;
    outline: none !important;
    text-align: center; }
    .history-item:before {
      content: "";
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: #274c7f;
      position: absolute;
      top: 8px;
      left: 50%;
      margin-left: -10px;
      transition: all ease-out 150ms; }
    .history-item:after {
      content: "";
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background: #fff;
      position: absolute;
      top: 11px;
      left: 50%;
      margin-left: -7px;
      opacity: 0;
      transition: all ease-out 150ms; }
    .history-item .year {
      font-weight: bold;
      margin-bottom: 1.5rem;
      transition: all ease-in 300ms; }
      @media (min-width: 320px) {
        .history-item .year {
          font-size: 3.6rem; } }
      @media (min-width: 480px) {
        .history-item .year {
          font-size: 3.27273rem; } }
      @media (min-width: 545px) {
        .history-item .year {
          font-size: 3rem; } }
      @media (min-width: 768px) {
        .history-item .year {
          font-size: 2.4rem; } }
      @media (min-width: 992px) {
        .history-item .year {
          font-size: 2.25rem; } }
      @media (min-width: 1200px) {
        .history-item .year {
          font-size: 2rem; } }
    .history-item .description {
      opacity: 0;
      transform: translate3d(0, 3rem, 0);
      transition: all ease-in 300ms;
      margin: 0 auto; }
      @media (min-width: 768px) {
        .history-item .description {
          max-width: 21rem; } }
    @media (min-width: 320px) {
      .history-item.slick-current .year {
        font-size: 4.4rem; } }
    @media (min-width: 480px) {
      .history-item.slick-current .year {
        font-size: 4rem; } }
    @media (min-width: 545px) {
      .history-item.slick-current .year {
        font-size: 3.66667rem; } }
    @media (min-width: 768px) {
      .history-item.slick-current .year {
        font-size: 2.93333rem; } }
    @media (min-width: 992px) {
      .history-item.slick-current .year {
        font-size: 2.75rem; } }
    @media (min-width: 1200px) {
      .history-item.slick-current .year {
        font-size: 2.5rem; } }
    .history-item.slick-current .description {
      opacity: 1;
      transform: translate3d(0, 0, 0); }
    .history-item.slick-current:before {
      width: 37px;
      height: 37px;
      top: 0;
      margin-left: -18px; }
    .history-item.slick-current:after {
      opacity: 1; }
  .history .slick-arrow {
    border-radius: 50%;
    top: 1rem;
    width: 4.7rem;
    height: 4.7rem; }
    @media (min-width: 768px) {
      .history .slick-arrow {
        top: 0; } }
    @media (min-width: 1200px) {
      .history .slick-arrow {
        top: -0.6rem; } }
    .history .slick-arrow.slick-prev {
      left: 3rem; }
      @media (min-width: 545px) {
        .history .slick-arrow.slick-prev {
          left: -4rem; } }
      @media (min-width: 992px) {
        .history .slick-arrow.slick-prev {
          left: -10rem; } }
      .history .slick-arrow.slick-prev:before {
        padding-right: 0.4rem; }
    .history .slick-arrow.slick-next {
      left: initial;
      right: 3rem; }
      @media (min-width: 545px) {
        .history .slick-arrow.slick-next {
          right: -4rem; } }
      @media (min-width: 992px) {
        .history .slick-arrow.slick-next {
          right: -10rem; } }
      .history .slick-arrow.slick-next:before {
        padding-left: 0.4rem; }

.counters {
  display: flex;
  margin: 6rem auto 0;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 250px; }
  @media (min-width: 545px) {
    .counters {
      max-width: 500px; } }
  @media (min-width: 1200px) {
    .counters {
      max-width: 100%; } }
  .counters .counter {
    padding: 3rem;
    text-align: center;
    min-width: 240px;
    max-width: 24rem;
    width: 100%;
    position: relative; }
    @media (min-width: 545px) {
      .counters .counter:after {
        content: "";
        width: 2px;
        height: 4rem;
        background: #fff;
        opacity: 0.25;
        position: absolute;
        right: -1px;
        top: 5.7rem; } }
    @media (min-width: 545px) {
      .counters .counter:nth-of-type(2n + 2):after {
        display: none; } }
    @media (min-width: 1200px) {
      .counters .counter:nth-of-type(2n + 2):after {
        display: block; } }
    @media (min-width: 1200px) {
      .counters .counter:nth-of-type(4n + 4):after {
        display: none; } }
    .counters .counter .counter-item {
      display: flex;
      font-size: 6rem;
      color: #8bbe1c;
      position: relative;
      font-weight: bold;
      justify-content: center; }
      .counters .counter .counter-item:before {
        content: "";
        position: absolute;
        width: 13.6rem;
        height: 13.6rem;
        background: #fff;
        border-radius: 50%;
        opacity: 0.07;
        top: 50%;
        left: 50%;
        margin-left: -6.8rem;
        margin-top: -6.8rem; }
      .counters .counter .counter-item .unit {
        font-size: 3.2rem;
        display: flex;
        margin-left: 1rem;
        height: 6rem;
        align-self: center;
        align-items: flex-end;
        margin-top: 0.4rem; }
    .counters .counter .description {
      max-width: 170px;
      margin-left: auto;
      margin-right: auto; }
      @media (min-width: 992px) {
        .counters .counter .description {
          max-width: 17rem; } }
    @media (min-width: 992px) {
      .counters .counter {
        width: 25%; } }
    .counters .counter:last-of-type:after {
      display: none; }

.content-row {
  display: flex;
  flex-wrap: wrap; }
  .content-row-img {
    position: relative;
    width: 100%;
    order: 3;
    text-align: center; }
    @media (min-width: 992px) {
      .content-row-img {
        width: 67%;
        order: 1; } }
    @media (min-width: 992px) {
      .content-row-img img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -55%, 0); } }
  .content-row-text {
    padding: 11rem 0 0 2.5rem;
    width: 100%;
    order: 2; }
    @media (min-width: 992px) {
      .content-row-text {
        padding: 11rem 0rem 11rem 2.5rem; } }
    .content-row-text .btn {
      margin: 1rem 0; }
    @media (min-width: 992px) {
      .content-row-text {
        width: 33%; } }

.articles {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -2.5rem;
  padding: 3rem 0 0 0; }
  .articles .article {
    padding: 0 2.5rem 8rem;
    color: #2b2b2b;
    text-decoration: none !important;
    font-weight: 400;
    width: 100%;
    margin-bottom: 3rem; }
    @media (min-width: 768px) {
      .articles .article {
        width: 33.333%;
        padding-bottom: 3rem; } }
    .articles .article-img {
      width: 100%;
      padding-top: 52%;
      background-size: cover !important;
      background-position: center center !important;
      margin-bottom: 3rem; }
    .articles .article-name {
      color: #274c7f;
      font-weight: bold;
      margin-bottom: 1.5rem;
      transition: all ease-out 200ms; }
      @media (min-width: 320px) {
        .articles .article-name {
          font-size: 4rem; } }
      @media (min-width: 480px) {
        .articles .article-name {
          font-size: 3.63636rem; } }
      @media (min-width: 545px) {
        .articles .article-name {
          font-size: 3.33333rem; } }
      @media (min-width: 768px) {
        .articles .article-name {
          font-size: 2.66667rem; } }
      @media (min-width: 992px) {
        .articles .article-name {
          font-size: 2.5rem; } }
      @media (min-width: 320px) {
        .articles .article-name {
          line-height: 4rem; } }
      @media (min-width: 480px) {
        .articles .article-name {
          line-height: 3.63636rem; } }
      @media (min-width: 545px) {
        .articles .article-name {
          line-height: 3.33333rem; } }
      @media (min-width: 768px) {
        .articles .article-name {
          line-height: 3rem; } }
    .articles .article-description {
      margin-bottom: 2rem; }
    .articles .article .read-more {
      color: #8bbe1c;
      font-weight: bold; }
      .articles .article .read-more:after {
        content: "\e903";
        position: relative;
        left: 1rem;
        top: 0.1rem;
        font-size: 1.3rem;
        font-weight: 700; }
    .articles .article:hover .article-name, .articles .article:focus .article-name {
      color: #8bbe1c; }

.solutions-nav, .solutions h3 {
  color: #fff; }

.solutions h3 {
  margin-bottom: 2rem; }

.solutions h3, .solutions span {
  position: relative; }

.solutions-nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 3rem; }

.solutions .solution-tab {
  padding: 3rem 12rem 3rem 3rem;
  background: #274c7f;
  position: relative;
  transition: all ease-in-out 250ms;
  cursor: pointer;
  width: 100%;
  margin-bottom: 10px; }
  @media all and (max-width: 991px) {
    .solutions .solution-tab {
      order: 1; } }
  @media (min-width: 992px) {
    .solutions .solution-tab {
      width: calc(50% - 2.5rem);
      margin-bottom: 0; } }
  .solutions .solution-tab:before {
    content: "";
    position: absolute;
    width: 5rem;
    height: 5rem;
    background: #274c7f;
    transition: all ease-in-out 250ms;
    transform: rotate(45deg);
    display: inline-block;
    bottom: 2rem;
    left: 4rem;
    z-index: 0; }
  .solutions .solution-tab:after {
    content: "\e904";
    height: 100%;
    width: 10rem;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 5.3rem;
    transition: all ease-in-out 250ms;
    -ms-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg); }
  .solutions .solution-tab.active {
    background: #8bbe1c; }
    @media all and (max-width: 991px) {
      .solutions .solution-tab.active {
        order: 2; } }
    .solutions .solution-tab.active:before {
      background: #8bbe1c;
      bottom: -2rem; }
    .solutions .solution-tab.active:after {
      -ms-transform: rotate(0);
      -webkit-transform: rotate(0);
      transform: rotate(0); }
  .solutions .solution-tab[data-solution='1'] {
    transform: translate3d(0, 0, 0); }
  .solutions .solution-tab[data-solution='0'] {
    transform: translate3d(0, 0, 0); }
    .solutions .solution-tab[data-solution='0'].active {
      transform: translate3d(0, 100%, 0); }
      @media (min-width: 992px) {
        .solutions .solution-tab[data-solution='0'].active {
          transform: translate3d(0, 0, 0); } }
      .solutions .solution-tab[data-solution='0'].active + [data-solution='1'] {
        transform: translate3d(0, -100%, 0); }
        @media (min-width: 992px) {
          .solutions .solution-tab[data-solution='0'].active + [data-solution='1'] {
            transform: translate3d(0, 0, 0); } }

.solutions .solution-content {
  background: #f4f6f8;
  padding: 4rem 6rem;
  display: none; }
  .solutions .solution-content h2 {
    margin-top: 0; }
  .solutions .solution-content-inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    .solutions .solution-content-inner .left, .solutions .solution-content-inner .right {
      width: 100%; }
      @media (min-width: 768px) {
        .solutions .solution-content-inner .left, .solutions .solution-content-inner .right {
          width: calc(50% - 2.5rem); } }
    .solutions .solution-content-inner .right {
      margin-top: 4rem; }
      @media (min-width: 768px) {
        .solutions .solution-content-inner .right {
          margin-top: 0; } }
    .solutions .solution-content-inner iframe {
      width: 100% !important; }
  .solutions .solution-content img {
    display: block; }

.solutions-content h3 {
  color: #274c7f !important; }

#solutions.relative-anchor {
  position: relative;
  top: 40px; }

.company-types {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 4rem 0 0;
  padding: 0 0 8rem 0; }
  .company-types .company-type {
    max-width: 500px;
    margin: 0 auto 5rem; }
    @media (min-width: 992px) {
      .company-types .company-type {
        max-width: 38rem;
        margin-bottom: 0; } }
    .company-types .company-type h4 {
      margin: 1.5rem 0; }
      @media (min-width: 320px) {
        .company-types .company-type h4 {
          font-size: 4rem; } }
      @media (min-width: 480px) {
        .company-types .company-type h4 {
          font-size: 3.63636rem; } }
      @media (min-width: 545px) {
        .company-types .company-type h4 {
          font-size: 3.33333rem; } }
      @media (min-width: 768px) {
        .company-types .company-type h4 {
          font-size: 2.66667rem; } }
      @media (min-width: 992px) {
        .company-types .company-type h4 {
          font-size: 2.5rem; } }
      @media (min-width: 320px) {
        .company-types .company-type h4 {
          line-height: 4rem; } }
      @media (min-width: 480px) {
        .company-types .company-type h4 {
          line-height: 3.63636rem; } }
      @media (min-width: 545px) {
        .company-types .company-type h4 {
          line-height: 3.33333rem; } }
      @media (min-width: 768px) {
        .company-types .company-type h4 {
          line-height: 2.8rem; } }
    .company-types .company-type .text {
      margin-bottom: 2rem; }
      @media (min-width: 992px) {
        .company-types .company-type .text {
          min-height: 17rem; } }
    @media (min-width: 992px) {
      .company-types .company-type:nth-of-type(2) {
        padding-left: 2rem;
        padding-right: 2rem; } }
    .company-types .company-type .benefit {
      padding: 1rem 0 1rem 7rem;
      position: relative; }
      .company-types .company-type .benefit:before {
        position: absolute;
        font-size: 4rem;
        color: #274c7f;
        left: 0;
        top: 1.4rem;
        display: inline-block; }
      .company-types .company-type .benefit.ico-desktop:before {
        content: "\e918"; }
      .company-types .company-type .benefit.ico-clock:before {
        content: "\e914"; }
      .company-types .company-type .benefit.ico-coin:before {
        content: "\e917"; }
      .company-types .company-type .benefit.ico-arrows:before {
        content: "\e915"; }
      .company-types .company-type .benefit.ico-system:before {
        content: "\e916";
        font-size: 3.2rem; }
      .company-types .company-type .benefit.ico-user:before {
        content: "\e919"; }
      .company-types .company-type .benefit.ico-end:before {
        content: "\e923"; }
      .company-types .company-type .benefit.ico-notification:before {
        content: "\e922"; }
      .company-types .company-type .benefit.ico-communication:before {
        content: "\e920"; }
      .company-types .company-type .benefit.ico-prices:before {
        content: "\e91f"; }
      .company-types .company-type .benefit.ico-gdpr:before {
        content: "\e921"; }
    .company-types .company-type img {
      transform: scale(0.8); }
      @media (min-width: 768px) {
        .company-types .company-type img {
          transform: scale(1); } }

.nav-container {
  display: none; }
  @media (min-width: 1200px) {
    .nav-container {
      position: relative;
      display: block; } }
  .nav-container .fixed-nav-helper {
    padding-top: 7rem; }

.detail-nav {
  background: #fff;
  transform: translate3d(0, 0, 0);
  position: absolute;
  width: 100%; }
  .detail-nav ul {
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex; }
    .detail-nav ul li {
      flex-grow: 1;
      border-top: 2px solid #f4f6f8;
      border-bottom: 2px solid #f4f6f8;
      border-left: 1px solid #f4f6f8;
      border-right: 1px solid #f4f6f8;
      padding: 0;
      margin: 0;
      height: 4.7rem; }
      .detail-nav ul li:before {
        display: none; }
      .detail-nav ul li:first-of-type {
        border-left: 0; }
      .detail-nav ul li:last-of-type {
        border-right: 0; }
      .detail-nav ul li a {
        width: 100%;
        display: block;
        padding: 1rem 3rem;
        text-align: center;
        text-transform: uppercase;
        font-weight: bold;
        color: #274c7f;
        letter-spacing: 0.02rem;
        font-size: 3.2rem; }
        @media (min-width: 480px) {
          .detail-nav ul li a {
            font-size: 2.90909rem; } }
        @media (min-width: 545px) {
          .detail-nav ul li a {
            font-size: 2.66667rem; } }
        @media (min-width: 768px) {
          .detail-nav ul li a {
            font-size: 2.13333rem; } }
        @media (min-width: 992px) {
          .detail-nav ul li a {
            font-size: 2rem; } }
        @media (min-width: 1200px) {
          .detail-nav ul li a {
            font-size: 1.77778rem; } }
        @media (min-width: 1400px) {
          .detail-nav ul li a {
            font-size: 1.6rem; } }
        .detail-nav ul li a:hover, .detail-nav ul li a:focus {
          text-decoration: none;
          background: #274c7f;
          color: #fff; }

.fix .detail-nav {
  position: fixed;
  top: 0;
  left: 0;
  transform: translate3d(0, 8.2rem, 0);
  z-index: 10; }

#contact.relative-anchor {
  top: -310px;
  position: relative; }
  @media (min-width: 1400px) {
    #contact.relative-anchor {
      top: -350px; } }

.people {
  display: flex;
  flex-wrap: wrap;
  margin: 8rem 0 11rem; }
  .people .item {
    display: flex;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 500px;
    margin-bottom: 5rem; }
    @media (min-width: 992px) {
      .people .item {
        width: 33.333%;
        margin-bottom: 0; } }
    .people .item .info {
      max-width: 60rem;
      padding-left: 2.5rem;
      font-style: italic;
      line-height: 24px;
      padding-top: 3rem; }
      @media (min-width: 992px) {
        .people .item .info {
          max-width: 23rem; } }
    .people .item .name {
      font-weight: bold;
      color: #274c7f;
      font-style: normal;
      margin-bottom: 1.5rem; }
      @media (min-width: 320px) {
        .people .item .name {
          font-size: 4rem; } }
      @media (min-width: 480px) {
        .people .item .name {
          font-size: 3.63636rem; } }
      @media (min-width: 545px) {
        .people .item .name {
          font-size: 3.33333rem; } }
      @media (min-width: 768px) {
        .people .item .name {
          font-size: 2.66667rem; } }
      @media (min-width: 992px) {
        .people .item .name {
          font-size: 2.5rem; } }
      @media (min-width: 320px) {
        .people .item .name {
          line-height: 4rem; } }
      @media (min-width: 480px) {
        .people .item .name {
          line-height: 3.63636rem; } }
      @media (min-width: 545px) {
        .people .item .name {
          line-height: 3.33333rem; } }
      @media (min-width: 768px) {
        .people .item .name {
          line-height: 2.8rem; } }
    .people .item .img {
      width: 20rem;
      min-width: 20rem; }
      @media (min-width: 992px) {
        .people .item .img img {
          height: 280px !important; } }

.modules {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 5rem; }
  .modules .module {
    background: #fff;
    padding: 3rem 9rem 3rem 5rem;
    cursor: pointer;
    transition: all ease-out 200ms;
    margin-bottom: 5rem;
    position: relative;
    width: 100%;
    transition: all ease-in-out 200ms; }
    @media (min-width: 768px) {
      .modules .module {
        width: calc(50% - 2.5rem); } }
    @media (min-width: 1200px) {
      .modules .module {
        width: calc(33.333% - 3.33rem); } }
    .modules .module:before {
      content: "\e903";
      height: 4.4rem;
      width: 4.4rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #f4f6f8;
      color: #274c7f;
      font-size: 2rem;
      top: 3rem;
      right: 2rem;
      position: absolute;
      border-radius: 50%;
      padding-left: 0.3rem; }
    .modules .module-name {
      font-weight: bold;
      color: #274c7f;
      margin-bottom: 2rem;
      transition: all ease-out 200ms; }
      @media (min-width: 320px) {
        .modules .module-name {
          font-size: 4rem; } }
      @media (min-width: 480px) {
        .modules .module-name {
          font-size: 3.63636rem; } }
      @media (min-width: 545px) {
        .modules .module-name {
          font-size: 3.33333rem; } }
      @media (min-width: 768px) {
        .modules .module-name {
          font-size: 2.66667rem; } }
      @media (min-width: 992px) {
        .modules .module-name {
          font-size: 2.5rem; } }
      @media (min-width: 320px) {
        .modules .module-name {
          line-height: 4rem; } }
      @media (min-width: 480px) {
        .modules .module-name {
          line-height: 3.63636rem; } }
      @media (min-width: 545px) {
        .modules .module-name {
          line-height: 3.33333rem; } }
      @media (min-width: 768px) {
        .modules .module-name {
          line-height: 2.8rem; } }
    .modules .module-description {
      transition: all ease-out 200ms; }
    .modules .module-detail {
      display: none;
      position: relative;
      top: 0;
      left: 0;
      width: 100%;
      cursor: default;
      padding-bottom: 5rem; }
      .modules .module-detail .pux-container {
        padding: 0; }
      .modules .module-detail .module-detail-body {
        display: flex;
        flex-wrap: wrap; }
        .modules .module-detail .module-detail-body .left, .modules .module-detail .module-detail-body .right {
          width: 100%; }
          @media (min-width: 768px) {
            .modules .module-detail .module-detail-body .left, .modules .module-detail .module-detail-body .right {
              width: 50%; } }
        .modules .module-detail .module-detail-body .left {
          padding: 5rem;
          background: #fff;
          overflow: auto;
          max-height: 60vh; }
          .modules .module-detail .module-detail-body .left .module-name {
            color: #274c7f; }
        .modules .module-detail .module-detail-body .right {
          position: relative;
          height: 35vh; }
          @media (min-width: 768px) {
            .modules .module-detail .module-detail-body .right {
              height: unset; } }
          .modules .module-detail .module-detail-body .right .bg {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-size: cover !important;
            background-position: center center !important; }
          .modules .module-detail .module-detail-body .right.video {
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer; }
            .modules .module-detail .module-detail-body .right.video:before {
              content: "\e910";
              color: #8bbe1c;
              font-size: 4.5rem;
              height: 8rem;
              width: 8rem;
              background: #fff;
              display: inline-block;
              display: flex;
              justify-content: center;
              align-items: center;
              transition: all ease-in-out 200ms;
              opacity: 1;
              border-radius: 50%;
              padding-left: 1rem;
              z-index: 1; }
            .modules .module-detail .module-detail-body .right.video:hover {
              text-decoration: none; }
              .modules .module-detail .module-detail-body .right.video:hover:before {
                opacity: 0.7; }
    .modules .module-close {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 4.3rem;
      height: 4.3rem;
      background: #fff;
      border-radius: 50%;
      cursor: pointer;
      transition: all ease-out 200ms;
      position: absolute;
      top: 2rem;
      right: 2rem;
      z-index: 2; }
      .modules .module-close:before {
        content: "\e905";
        font-size: 3.5rem;
        color: #2b2b2b;
        padding: 0.1rem 0 0 0.1rem; }
        @media (min-width: 768px) {
          .modules .module-close:before {
            font-size: 1.5rem; } }
      .modules .module-close:hover {
        opacity: 0.6; }
    .modules .module:hover {
      background: #274c7f; }
      .modules .module:hover .module-name, .modules .module:hover .module-description {
        color: #fff; }
    .modules .module:after {
      content: "";
      width: 0;
      height: 0;
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-top: 20px solid #274c7f;
      position: absolute;
      top: 100%;
      display: inline-block;
      transform: translate3d(0, -10px, 0) scale(0);
      z-index: 0;
      transition: all ease-in-out 200ms; }
    .modules .module.active {
      background: #274c7f;
      color: #fff; }
      .modules .module.active .module-name {
        color: #fff; }
      .modules .module.active:after {
        transform: translate3d(0, 0, 0) scale(1); }
  .modules .detail-placeholder {
    width: 100%; }

.references {
  margin-top: 5rem;
  display: flex;
  flex-wrap: wrap; }
  .references .reference {
    border: 2px solid #f4f6f8;
    padding: 4rem;
    width: 100%;
    margin-bottom: 5rem; }
    @media (min-width: 992px) {
      .references .reference {
        width: calc(33.333% - 3.333rem);
        margin-right: 4.9rem; } }
    @media (min-width: 992px) {
      .references .reference:nth-of-type(3n + 3) {
        margin-right: 0; } }
    .references .reference .img-wrap {
      margin-bottom: 4rem; }
      @media (min-width: 992px) {
        .references .reference .img-wrap {
          display: flex;
          align-items: center;
          min-height: 8.5rem; } }
      .references .reference .img-wrap img {
        filter: grayscale(100%);
        opacity: 0.5; }
    .references .reference h3 {
      margin: 0 0 1.5rem; }
    .references .reference strong {
      display: block;
      margin-bottom: 3rem; }

.references-slider .reference {
  margin: 0; }
  @media (min-width: 992px) {
    .references-slider .reference {
      margin: 0 2.5rem; } }

.references-slider .slick-arrow {
  top: 50%;
  margin-top: -2.7rem;
  background: #274c7f; }
  .references-slider .slick-arrow.slick-next {
    left: auto;
    right: -3rem; }
    @media (min-width: 992px) {
      .references-slider .slick-arrow.slick-next {
        right: -1.5rem; } }
  .references-slider .slick-arrow.slick-prev {
    left: -3rem; }
    @media (min-width: 992px) {
      .references-slider .slick-arrow.slick-prev {
        left: -1.5rem; } }

.slider-container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  max-width: 150rem;
  overflow: hidden; }
  @media (min-width: 768px) {
    .slider-container {
      padding-left: 3rem;
      padding-right: 3rem; } }

.filter {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding-top: 6.5rem; }
  .filter .filter-item {
    border-radius: 5rem;
    cursor: pointer;
    padding: 1rem 3rem;
    background: #f4f6f8;
    margin: 2.5rem 2.5rem 0 2.5rem;
    transition: all ease-in-out 200ms; }
    .filter .filter-item:not(.active):hover {
      background: #e4e9ee; }
    .filter .filter-item.active {
      background: #274c7f;
      color: #fff; }

[data-inline-editor='structured-data-editor'] {
  width: 100%;
  color: #2b2b2b !important; }
  [data-inline-editor='structured-data-editor'] input[type='text'] {
    height: 30px;
    padding: 0 5px; }

.structed-content-items-container {
  list-style-type: none;
  position: relative;
  padding: 0 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  background: #efeded; }
  .structed-content-items-container:nth-of-type(even) {
    background: #fbfbfb; }
  .structed-content-items-container [data-pux-strcon-remove] {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 0; }
    .structed-content-items-container [data-pux-strcon-remove]:before {
      content: "\e6d0";
      color: red;
      text-decoration: none !important;
      font-family: 'Core-icons' !important;
      font-size: 16px; }

.structed-content-item {
  padding: 5px;
  width: 25%; }

.history .structed-content-item {
  width: 100%; }

.structured-content-controls {
  padding: 10px 0 40px;
  text-align: center; }

.kentico-edit-mode .header-block {
  overflow: visible !important; }

@media print {
  header,
  footer {
    display: none; } }
