@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/pux-content.scss";
@import "_mixins/pux/scale.scss";
@import "_variables.scss";

.filter {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding-top: 6.5rem;


  .filter-item {
    border-radius: 5rem;
    cursor: pointer;
    padding: 1rem 3rem;
    background: $base-grey;
    margin: 2.5rem 2.5rem 0 2.5rem;
    transition: all ease-in-out 200ms;

    &:not(.active):hover {
      background: darken($base-grey, 5%);
    }

    &.active {
      background: $color-blue;
      color: $base-white;
    }
  }
}
