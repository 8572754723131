
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/pux-content.scss";
@import "_mixins/pux/scale.scss";
@import "_variables.scss";

[data-inline-editor='structured-data-editor'] {
  width: 100%;
  color: $base-black !important;

  input[type='text'] {
    height: 30px;
    padding: 0 5px;
  }
}

.structed-content-items-container {
  list-style-type: none;
  position: relative;
  padding: 0 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;

  background: #efeded;
  &:nth-of-type(even) {
    background: #fbfbfb;
  }

  [data-pux-strcon-remove] {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 0;

    &:before {
      content: "\e6d0";
      color: red;
      text-decoration: none !important;
      font-family: 'Core-icons' !important;
      font-size: 16px;
    }
  }
}

.structed-content-item {
  padding: 5px;
  width: 25%;
}


.history {
  .structed-content-item {

    width: 100%;
  }
}

.structured-content-controls {
  padding: 10px 0 40px;
  text-align: center;
}

.kentico-edit-mode {

  .header-block {
    overflow: visible !important;
  }
}
