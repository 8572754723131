
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/pux-content.scss";
@import "_mixins/pux/scale.scss";
@import "_variables.scss";

.counters {
  display: flex;
  margin: 6rem auto 0;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 250px;

  @include media(sm) {
    max-width: 500px;
  };

  @include media(xl) {
    max-width: 100%;
  };

  .counter {
    padding: 3rem;
    text-align: center;
    min-width: 240px;
    max-width: 24rem;
    width: 100%;
    position: relative;

    @include media(sm) {
      &:after {
        content: "";
        width: 2px;
        height: 4rem;
        background: $base-white;
        opacity: 0.25;
        position: absolute;
        right: -1px;
        top: 5.7rem;
      }
    };

    &:nth-of-type(2n + 2) {

      &:after {

        @include media(sm) {
          display: none;
        };
      }
    }

    &:nth-of-type(2n + 2) {

      &:after {

        @include media(xl) {
          display: block;
        };
      }
    }

    &:nth-of-type(4n + 4) {

      &:after {

        @include media(xl) {
          display: none;
        };
      }
    }

    .counter-item {
      display: flex;
      font-size: 6rem;
      color: $color-green;
      position: relative;
      font-weight: bold;
      justify-content: center;

      &:before {
        content: "";
        position: absolute;
        width: 13.6rem;
        height: 13.6rem;
        background: $base-white;
        border-radius: 50%;
        opacity: 0.07;
        top: 50%;
        left: 50%;
        margin-left: -6.8rem;
        margin-top: -6.8rem;
      }

      .unit {
        font-size: 3.2rem;
        display: flex;
        margin-left: 1rem;
        height: 6rem;
        align-self: center;
        align-items: flex-end;
        margin-top: 0.4rem;
      }
    }

    .description {
      max-width: 170px;
      margin-left: auto;
      margin-right: auto;

      @include media(lg) {
        max-width: 17rem;
      };
    }

    @include media(lg) {
      width: 25%;
    };

    &:last-of-type {

      &:after {
        display: none;
      }
    }
  }
}
