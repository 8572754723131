
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/pux-content.scss";
@import "_mixins/pux/scale.scss";
@import "_variables.scss";

.company-types {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 4rem 0 0;
  padding: 0 0 8rem 0;

  .company-type {
    max-width: 500px;
    margin: 0 auto 5rem;

    @include media(lg) {
      max-width: 38rem;
      margin-bottom: 0;
    };

    h4 {
      @include pux-scale-with-min("font-size", 25px, 20px);
      @include pux-scale-with-min("line-height", 28px, 20px);
      margin: 1.5rem 0;
    }

    .text {
      margin-bottom: 2rem;

      @include media(lg) {
        min-height: 17rem;
      };
    }

    &:nth-of-type(2) {
      @include media(lg) {
        padding-left: 2rem;
        padding-right: 2rem;
      };
    }

    .benefit {
      padding: 1rem 0 1rem 7rem;
      position: relative;

      &:before {
        position: absolute;
        font-size: 4rem;
        color: $color-blue;
        left: 0;
        top: 1.4rem;
        display: inline-block;
      }

      &.ico-desktop:before {
        content: "\e918";
      }

      &.ico-clock:before {
        content: "\e914";
      }

      &.ico-coin:before {
        content: "\e917";
      }

      &.ico-arrows:before {
        content: "\e915";
      }

      &.ico-system:before {
        content: "\e916";
        font-size: 3.2rem;
      }

      &.ico-user:before {
        content: "\e919";
      }


      &.ico-end:before {
        content: "\e923";
      }
      &.ico-notification:before {
        content: "\e922";
      }
      &.ico-communication:before {
        content: "\e920";
      }
      &.ico-prices:before {
        content: "\e91f";
      }
      &.ico-gdpr:before {
        content: "\e921";
      }
    }

    img {
      transform: scale(0.8);

      @include media(md) {
        transform: scale(1);
      };
    }
  }
}
