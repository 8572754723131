
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/pux-content.scss";
@import "_mixins/pux/scale.scss";
@import "_variables.scss";

.history-wrap {
  border-top: 3px solid $base-grey;
  margin-top: 9rem;
}

.history {
  top: -20px;
  position: relative;
  max-width: 70rem;
  margin: 0 auto;

  &-item {
    padding: 7rem 0;
    position: relative;
    outline: none !important;
    text-align: center;

    &:before {
      content: "";
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: $color-blue;
      position: absolute;
      top: 8px;
      left: 50%;
      margin-left: -10px;
      transition: all ease-out 150ms;
    }

    &:after {
      content: "";
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background: $base-white;
      position: absolute;
      top: 11px;
      left: 50%;
      margin-left: -7px;
      opacity: 0;
      transition: all ease-out 150ms;
    }


    .year {
      @include pux-scale-with-min("font-size", 20px, 18px);
      font-weight: bold;
      margin-bottom: 1.5rem;
      transition: all ease-in 300ms;
    }

    .description {
      opacity: 0;
      transform: translate3d(0,3rem,0);
      transition: all ease-in 300ms;
      margin: 0 auto;

      @include media(md) {
        max-width: 21rem;
      };
    }

    &.slick-current {

      .year {
        @include pux-scale-with-min("font-size", 25px, 22px);
      }

      .description {
        opacity: 1;
        transform: translate3d(0,0,0);
      }

      &:before {
        width: 37px;
        height: 37px;
        top: 0;
        margin-left: -18px;
      }

      &:after {
        opacity: 1;
      }
    }
  }

  .slick-arrow {
    border-radius: 50%;
    top: 1rem;
    width: 4.7rem;
    height: 4.7rem;

    @include media(md) {
      top: 0;
    };

    @include media(xl) {
      top: -0.6rem;
    };

    &.slick-prev {
      left: 3rem;

      @include media(sm) {
        left: -4rem;
      };

      @include media(lg) {
        left: -10rem;
      };

      &:before {
        padding-right: 0.4rem;
      }
    }

    &.slick-next {
      left: initial;
      right: 3rem;

      @include media(sm) {
        right: -4rem;
      };

      @include media(lg) {
        right: -10rem;
      };

      &:before {
        padding-left: 0.4rem;
      }
    }
  }
}
