
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/pux-content.scss";
@import "_mixins/pux/scale.scss";
@import "_variables.scss";

.solutions  {

  &-nav, h3 {
    color: $base-white;
  }

  h3 {
    margin-bottom: 2rem;
  }

  h3, span {
    position: relative;
  }

  &-nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 3rem;
  }

  .solution-tab {
    padding: 3rem 12rem 3rem 3rem;
    background: $color-blue;
    position: relative;
    transition: all ease-in-out 250ms;
    cursor: pointer;
    width: 100%;
    margin-bottom: 10px;

    @media all and (max-width: 991px) {
      order: 1;
    }

    @include media(lg) {
      width: calc(50% - 2.5rem);
      margin-bottom: 0;
    };

    &:before {
      content: "";
      position: absolute;
      width: 5rem;
      height: 5rem;
      background: $color-blue;
      transition: all ease-in-out 250ms;
      transform: rotate(45deg);
      display: inline-block;
      bottom: 2rem;
      left: 4rem;
      z-index: 0;
    }

    &:after {
      content: "\e904";
      height: 100%;
      width: 10rem;
      position: absolute;
      top: 0;
      right: 0;
      @include flexCenter();
      font-size: 5.3rem;
      transition: all ease-in-out 250ms;
      @include rotate(180deg);
    }

    &.active {
      background: $color-green;

      @media all and (max-width: 991px) {
        order: 2;
      }

      &:before {
        background: $color-green;
        bottom: -2rem
      }

      &:after {
        @include rotate(0);
      }
    }

    &[data-solution='1'] {
      transform: translate3d(0,0,0);
    }

    &[data-solution='0'] {
      transform: translate3d(0,0,0);

      &.active {
        transform: translate3d(0,100%,0);

        @include media(lg) {
          transform: translate3d(0,0,0);
        };

        &+[data-solution='1'] {
          transform: translate3d(0,-100%,0);

          @include media(lg) {
            transform: translate3d(0,0,0);
          };
        }
      }
    }
  }

  .solution-content {
    background: $base-grey;
    padding: 4rem 6rem;
    display: none;

    h2 {
      margin-top: 0;
    }

    &-inner {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .left, .right {
        width: 100%;

        @include media(md) {
          width: calc(50% - 2.5rem);
        };
      }

      .right {
        margin-top: 4rem;

        @include media(md) {
          margin-top: 0;
        };
      }

      iframe {
        width: 100% !important;
      }
    }

    img {
      display: block;
    }
  }
}

.solutions-content {
  h3 {
    color: $main-color !important;
  }
}



#solutions.relative-anchor {
  position: relative;
  top: 40px;
}
