
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/pux-content.scss";
@import "_mixins/pux/scale.scss";
@import "_variables.scss";

.articles {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -2.5rem;
  padding: 3rem 0 0 0;

  .article {
    padding: 0 2.5rem 8rem;
    color: $base-black;
    text-decoration: none !important;
    font-weight: 400;
    width: 100%;
    margin-bottom: 3rem;

    @include media(md) {
      width: 33.333%;
      padding-bottom: 3rem;
    };

    &-img {
      width: 100%;
      padding-top: 52%;
      background-size: cover !important;
      background-position: center center !important;
      margin-bottom: 3rem;
    }

    &-name {
      @include pux-scale-with-min("font-size", 25px, 20px);
      @include pux-scale-with-min("line-height", 30px, 20px);
      color: $main-color;
      font-weight: bold;
      margin-bottom: 1.5rem;
      transition: all ease-out 200ms;
    }

    &-description {
      margin-bottom: 2rem;
    }

    .read-more {
      color: $color-green;
      font-weight: bold;

      &:after {
        content: "\e903";
        position: relative;
        left: 1rem;
        top: 0.1rem;
        font-size: 1.3rem;
        font-weight: 700;
      }
    }

    &:hover, &:focus {

      .article-name {
        color: $color-green;
      }
    }
  }
}
