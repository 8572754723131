
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/pux-content.scss";
@import "_mixins/pux/scale.scss";
@import "_variables.scss";

.nav-container {
  display: none;

  @include media(xl) {
    position: relative;
    display: block;
  };

  .fixed-nav-helper {
    padding-top: 7rem;
  }
}

.detail-nav {
  background: $base-white;
  transform: translate3d(0,0,0);
  position: absolute;
  width: 100%;

  ul {
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;

    li {
      flex-grow: 1;
      border-top: 2px solid $base-grey;
      border-bottom: 2px solid $base-grey;
      border-left: 1px solid $base-grey;
      border-right: 1px solid $base-grey;
      padding: 0;
      margin: 0;
      height: 4.7rem;

      &:before {
        display: none;
      }

      &:first-of-type {
        border-left: 0;
      }

      &:last-of-type {
        border-right: 0;
      }

      a {
        width: 100%;
        display: block;
        padding: 1rem 3rem;
        text-align: center;
        text-transform: uppercase;
        font-weight: bold;
        color: $color-blue;
        letter-spacing: 0.02rem;
        @include pux-static-font(16px);

        &:hover, &:focus {
          text-decoration: none;
          background: $color-blue;
          color: $base-white;
        }
      }
    }
  }
}

.fix .detail-nav {
  position: fixed;
  top: 0;
  left: 0;
  transform: translate3d(0,8.2rem,0);
  z-index: 10;
}

#contact.relative-anchor {
  top: -310px;
  position: relative;

  @include media(xxl) {
    top: -350px;
  };
}
