
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/pux-content.scss";
@import "_mixins/pux/scale.scss";
@import "_variables.scss";

.services {
  margin: 20rem 0 0;

  @include media(md) {
    margin: 8rem 0 0;
  };

  .top {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;

    @include media(md) {
      margin-bottom: 7rem;
    };
  }

  .service {
    position: relative;
    display: flex;
    flex-flow: wrap column;
    justify-content: center;
    align-items: center;
    padding: 5rem;
    min-height: 30rem;
    width: 50%;

    @include media(md) {
      border-radius: 50%;
      background: rgba($color-blue, 0.1);
      width: 46rem;
      height: 46rem;
    };

    h3 {
      text-align: center;
      @include pux-scale-with-min("font-size", 30px, 18px);
      @include pux-scale-with-min("line-height", 36px, 20px);
      margin-top: 3rem;
      transition: all ease-in 250ms;

      //@include media(md) {
        max-width: 28rem;
      //};
    }

    &:first-of-type {

      @include media(md) {
        transform: translate3d(2.5rem, 0, 0);
      };

      img {
        width: 21.5rem;
        height: 18.7rem;
      }
    }

    &:last-of-type {

      @include media(md) {
        transform: translate3d(-2.5rem, 0, 0);
      };

      img {
        width: 24.8rem;
        height: 18.4rem;
      }
    }

    img {
      transition: all ease-in 250ms;
    }

    &:hover {
      text-decoration: none;

      h3 {
        color: $color-green;
      }

      img {
        transform: scale(0.9);
      }
    }
  }

  .bottom {
    display: flex;
    flex-flow: row wrap;
  }

  .subservice {
    width: 50%;
    padding: 14rem 4.5rem 3rem;
    position: relative;
    text-align: center;
    margin-top: 8rem;

    @include media(md) {
      width: 20%;
      margin-top: 0;
    };

    &:before {
      color: #2b4261;
      position: absolute;
      font-size: 9.5rem;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    &:after {
      content: "";
      position: absolute;
      width: 3px;
      height: 5rem;
      background: $color-green;
      left: 50%;
      margin-left: -1px;
      top: -8rem;

      @include media(md) {
        margin-left: 0;
        background-size: contain !important;
        background-repeat: no-repeat !important;
      };
    }

    &:nth-of-type(1){

      &:before {
        content: "\e908";
      }

      &:after {
        @include media(md) {
          width: 16.5rem;
          height: 10.9rem;
          top: -13.5rem;
          left: 13rem;
          background: url('../img/service-line1.svg');
        };
      }
    }

    &:nth-of-type(2){

      &:before {
        content: "\e90b";
      }

      &:after {
        @include media(md) {
          width: 3.8rem;
          height: 4rem;
          top: -5.5rem;
          left: 16rem;
          background: url('../img/service-line2.svg');
        };
      }
    }

    &:nth-of-type(3){

      &:before {
        content: "\e90a";
      }

      &:after {
        @include media(md) {
          width: 3px;
          height: 13.5rem;
          top: -15.5rem;
          left: 50%;
          margin-left: -1px;
          background: $color-green;
        };
      }
    }

    &:nth-of-type(4){

      &:before {
        content: "\e924";
      }

      &:after {
        @include media(md) {
          width: 3.3rem;
          height: 4.6rem;
          top: -6rem;
          left: 10rem;
          background: url('../img/service-line4.svg');
        };
      }
    }

    &:nth-of-type(5){

      &:before {
        content: "\e925";
      }

      &:after {
        @include media(md) {
          width: 12.5rem;
          height: 13.8rem;
          top: -16rem;
          left: 1rem;
          background: url('../img/service-line5.svg');
        };
      }
    }
  }
}

#service-more {

  &:before {
    content: "";
    position: absolute;
    display: inline-block;
    width: 179px;
    height: 65px;
    background: url('../img/sipka-text-mobil.svg');
    background-size: contain !important;
    background-repeat: no-repeat !important;
    top: -60px;
    left: 100%;
    margin-left: -89px;

    @include media(md) {
      margin-left: 0;
      top: -37px;
      left: -40px;
      background: url('../img/sipka-text.svg');
      width: 260px;
      height: 93px;



    };

    @include media(xl) {
      top: -14px;
      left: -152px;
    };
  }
}
