
$px-values: (5px, 10px, 15px, 20px, 25px, 30px, 35px, 40px, 45px, 50px, 55px, 60px, 65px, 70px, 75px, 80px, 85px, 90px, 95px, 100px);

// bootstrap
$main-left: 15px;
$main-right: 15px;
$main-left-md: 3rem;
$main-right-md: 3rem;

// container
$container-max-width: 145rem;
$container-padding-left: $main-left;
$container-padding-right: $main-right;
$container-padding-left-md: $main-left-md;
$container-padding-right-md: $main-right-md;

// fluid container
$fluid-container-padding-left: $main-left;
$fluid-container-padding-right: $main-right;
$fluid-container-padding-left-md: $main-left-md;
$fluid-container-padding-right-md: $main-right-md;
$fluid-container-padding-left-xl: 12rem;
$fluid-container-padding-right-xl: 12rem;


// row
$row-margin-left: (-$main-left);
$row-margin-right: (-$main-right);
$row-margin-left-md: (-$main-left-md);
$row-margin-right-md: (-$main-right-md);

// col
$col-padding-left: $main-left;
$col-padding-right: $main-right;
$col-padding-left-md: $main-left-md;
$col-padding-right-md: $main-right-md;

// typography
$font-family: 'nimbus-sans', sans-serif;
$font-size: 18px;

// perex
$font-size-perex: 20px;
$min-font-size-perex: 18px;
$line-height-perex: 28px;
$min-line-height-perex: 24px;

// small text
$font-size-small: 14px;
$min-font-size-small: 14px;
$line-height-small: 18px;
$min-line-height-small: 18px;

// main colors
$color-green: #8bbe1c;
$color-blue: #274c7f;

$color-link: $color-green;
$main-color: $color-blue;
$color-line: #f1f1f1;
$base-black: #2b2b2b;
$base-white: #fff;
$base-grey: #f4f6f8;;
$second-color: $base-grey;

// button primary
$btn-primary-background: $color-green;
$btn-primary-color: $base-white;

// button default
$btn-default-background: transparent;
$btn-default-color: $color-blue;

// ul, li
$ul-bullet: $main-color;
$ol-number: $main-color;

// table
$table-even-background: $base-grey;
$table-tr-border: transparent;
$respo-table-break: "md";

// menu
$hamburger-width: 6rem;
$menu-break: "md";

// form
$color-placeholder: #000;

// inputs
$border-color: #e1e1e1;
$input-color: $base-black;
$input-background: $base-white;
$focus-border-color: $color-green;
$input-height: 50px;
$textarea-height: $input-height * 3;

// selectric
$select-background: $input-background;
$select-color: $input-color;
$select-border:#e1e1e1;
$select-border-focus: $color-green;
$select-arrow-color: $base-black;
$select-arrow-code: "";
$select-use-triangle: 0;
$select-height: $input-height;

// datepicker
$datepicker-header-background: darken($main-color, 5);
$datepicker-header-color: $base-white;
$datepicker-header-arrows-color: $base-white;
$datepicker-option-backgorund:$main-color;
$datepicker-option-color: $base-white;
$datepicker-selected-background: $main-color;
$datepicker-selected-color: $base-white;
$datepicker-full-background: $base-white;
$datepicker-th-color: #757575 ;
$datepicker-th-border: #e9ecef;
$datepicker-year-picker-color: $main-color;

// pokud se používá timepicker je potřeba definovat barvy i v /plugins/datetimepicker/bootstrap-material-datetimepicker.js

// upload
$upload-background: $main-color;
$upload-color: $base-white;
$file-name-background: $second-color;
$file-name-color: $base-black;

// form radio
$radio-background: $base-white;
$radio-border: #e1e1e1;
$radio-active-border: #e1e1e1;
$radio-after-background: $color-green;

// form checkbox
$checkbox-background: $base-white;
$checkbox-border: #e1e1e1;
$checkbox-active-border: #e1e1e1;
$checkbox-after-background: $color-green;

// form error
$error-input-border: #d40511;
$error-label-color: #d40511;
$error-blink-start: #fff;
$error-blink-final: #fec7ca;
$use-blink-error: 1;

//scale ratios
$scale-base-size: 10px;

$scale-ratio-xxs: 0.5;
$scale-ratio-xs: 0.55;
$scale-ratio-sm: 0.6;
$scale-ratio-md: 0.75;
$scale-ratio-lg:  0.8;
$scale-ratio-xl: 0.9;
$scale-ratio-xxl: 1;

//*** content ***

$slider-arrow-width: 4rem;
$slider-arrow-height: $slider-arrow-width;
$slider-arrow-background: $main-color;
$slider-arrow-radius: 50%;
$slider-arrow-border: none;
$slider-arrow-left: 2rem;
$slider-arrow-right: 2rem;
$slider-arrow-before-size: 20px;
$slider-arrow-prev-code: "\9057";
$slider-arrow-next-code: "\9053";

$slider-dot-width: 30px;
$slider-dot-height: $slider-dot-width;
$slider-dot-background: rgba(30,30,30,0.2);
$slider-dot-active: rgba(30,30,30,1);
$slider-dot-border: none;
$slider-dot-radius: 50%;
$slider-dot-padding: 0 2rem 0 0;
$slider-dot-bottom: 8rem;

//*** lazy load ***
$imgLoadAnimation: animateLoaded__ 0.1s ease-out forwards;
