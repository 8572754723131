
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/pux-content.scss";
@import "_mixins/pux/scale.scss";
@import "_variables.scss";

.cta-block {
  background-size: cover !important;
  padding: 7rem 5rem 8rem;
  margin: 7.5rem 0 9rem;
  text-align: center;
  position: relative;

  h2 {
    color: $base-white;
    max-width: 85rem;
    margin: 0 auto;
  }

  .btn {
    position: absolute;
    bottom: 0;
    transform: translate3d(-50%,50%,0);
  }
}

.IE {
  .cta-block {
    .btn {
      left: 50%;
    }
  }
}
