
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/pux-content.scss";
@import "_mixins/pux/scale.scss";
@import "_variables.scss";

.slider {
  padding: 4rem 0 4.5rem;

  .slide {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 5rem;
    outline: 0 !important;

    @include media(lg) {
      flex-wrap: nowrap;
    };

    .left {
      background: $color-blue;
      padding: 6rem;
      color: $base-white;
      transform: translate3d(-5rem, 0, 0);
      transition: all ease-out 200ms;
      width: 100%;

      @include media(lg) {
        padding: 6rem 16.5rem 6rem 7.5rem;
        width: calc(50% + 2.5rem);
        margin-right: -2.5rem;
      };
    }

    .right {
      background-size: cover !important;
      background-position: center center !important;
      transform: translate3d(5rem, 0, 0);
      transition: all ease-out 200ms;
      position: relative;
      width: 100%;
      padding-top: 66%;

      @include media(lg) {
        top: 5rem;
        width: calc(50% + 2.5rem);
        margin-left: -2.5rem;
        padding-top: 0;
      };

      .video {
        @include absoluteCover();
        @include flexCenter();

        &:before {
          content: "\e910";
          color: $color-link;
          font-size: 4.5rem;
          height: 8rem;
          width: 8rem;
          background: $base-white;
          display: inline-block;
          @include flexCenter();
          transition: all ease-in-out 200ms;
          opacity: 1;
          border-radius: 50%;
          padding-left: 1rem;
        }

        &:hover {
          text-decoration: none;

          &:before {
            opacity: 0.7;
          }
        }
      }
    }

    h2 {
      margin: 0 0 3rem 0;
      color: $base-white;
    }

    &.slick-active {

      .left, .right {
        transition: all ease-out 800ms;
        transform: translate3d(0, 0, 0);
      }
    }
  }
}


.slick-arrow {
  font-size: 0;
  width: 5.5rem;
  height: 5.5rem;
  border: 0;
  background: $color-green;
  @include flexCenter;
  transition: all ease-out 200ms;
  cursor: pointer;
  position: absolute;
  outline: 0 !important;
  z-index: 2;
  opacity: 1;
  transform: scale(1);
  top: 5.3rem;

  @include media(lg) {
    top: initial;
    bottom: 10.9rem;
  };

  &.slick-disabled {
    opacity: 0;
    transform: scale(0);
  }

  &:before {
    //@include pux-static-font(23px);
    font-size: 2.3rem;
    color: $base-white;
  }

  &:hover, &:focus {
    background: darken($color-green, 5%);
  }

  &.slick-prev {
    left: 9.5rem;

    @include media(lg) {
      left: 44.4rem;
    };

    &:before {
      content: "\e902";
    }
  }

  &.slick-next {
    left: 17rem;

    @include media(lg) {
      left: 51.4rem;
    };

    &:before {
      content: "\e903";
    }
  }
}
