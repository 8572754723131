
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/pux-content.scss";
@import "_mixins/pux/scale.scss";
@import "_variables.scss";

.pux-container{
    margin-left: auto;
    margin-right: auto;
    padding-left: $container-padding-left;
    padding-right: $container-padding-right;
    max-width: $container-max-width;
    &:after{
        display: block;
        content: "";
        clear: both;
    }
    @include media(md){
        padding-left: $container-padding-left-md;
        padding-right: $container-padding-right-md;
    }
}

.pux-container{
    .row{
        @include flex-block;
        @include flex-wrap(wrap);
        margin-left: $row-margin-left;
        margin-right: $row-margin-right;
        @include media(md){
          margin-left: $row-margin-left-md;
          margin-right: $row-margin-right-md;
        }
    }
}

.pux-container{
    [class^="col"]{
        padding-left: $col-padding-left;
        padding-right: $col-padding-right;
        @include media(md){
          padding-left: $col-padding-left-md;
          padding-right: $col-padding-right-md;
        }
    }
}

.pux-fluid-container{
    padding-left: $fluid-container-padding-left;
    padding-right: $fluid-container-padding-right;
    @include media(md){
      padding-left: $fluid-container-padding-left-md;
      padding-right: $fluid-container-padding-right-md;
    }
    @include media(xl){
      padding-left: $fluid-container-padding-left-xl;
      padding-right: $fluid-container-padding-right-xl;
    }
}

header{
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  transition: all ease-out 250ms;
  z-index: 50;
  border-bottom: 1px solid transparent;

  .header{
    @include flex-block;
    @include align-items(center);
    @include justify-content(space-between);
    padding: 4.5rem 0;
    transition: all ease-out 250ms;
    &-logo{
      transform: translateY(-1rem);
      a{
        display:inline-block;
        span{
          display:none;
        }
        img{
          height: 5.3rem;
          width: 23.8rem;
        }
      }
    }
    &-menu{
      position: absolute;
      top:14.3rem;
      z-index: 77;
      left:0;
      background: $second-color;
      width: 100%;

      padding: 3rem 4rem;
      transition: all ease-out 200ms;
      z-index: -1;
      opacity: 0;
      transform: translate3d(0,-1.5rem,0);
      pointer-events: none;

      @include calc("max-height", "100vh - 5rem");
      @include media($menu-break){
        padding: 0;
        z-index: 1;
        opacity: 1;
        transform: translate3d(0,0,0);
        pointer-events: all;
        @include flex-block;
        position: static;
        background: none;
        width:auto;
      }
      ul{
        margin:0;
        padding:0;
        list-style: none;
        @include media($menu-break){
          @include flex-block;
        }
        li{
          display: block;
          position: relative;
          @include media($menu-break){
            margin:0 1rem;
            @include flex-block;
            @include align-items(center);
          }
          a{
            display:block;
            padding:1.5rem 1.5rem;
            color:$main-color;
            text-transform: uppercase;
            font-weight: bold;
            letter-spacing: 1px;
            @include pux-static-font(16px);
            &:focus,
            &:hover{
              outline-color: none;
              color:$color-green;
              text-decoration: none;
            }
          }
          &.active{
            a{
              color: $color-green;
            }
          }
          ul{
            background: $second-color;
            display: none;
            width:100%;
            @include opacity(1);
            visibility: visible;
            @include transition;
            @include media($menu-break){
              position: absolute;
              width:20rem;
              visibility: hidden;
              @include opacity(0);
              display: block;
              top:100%;
            }
            li{
              a{
                &:focus,
                &:hover{

                }
              }
            }
          }
          &.hovered,
          &:hover{
            & > a{

            }
            & > ul {
              visibility: visible;
              @include opacity(1);
            }
          }
          &.open{
            .menu-openner{
              &:after{
                @include rotate(0deg);
              }
            }
            ul{
              display:block;
            }
          }
        }
      }
      .menu-openner{
        position: absolute;
        top:0;
        right:0;
        background: $main-color;
        color:#fff;
        width: 6rem;
        height: 6rem;
        @include media($menu-break){
          display: none;
        }
        &:after,
        &:before{
          content:"";
          width:70%;
          height:2px;
          background:#fff;
          position: absolute;
          left:15%;
          top:50%;
          margin-top:-1px;
        }
        &:after{
          @include rotate(90deg);
          @include transition;
        }
      }
    }
    &-hamburger{
      @include media($menu-break){
        display: none;
      }
      .open-menu{
        width:$hamburger-width;
        height:$hamburger-width;
        position: relative;
        display: block;
        span{
          width:$hamburger-width - 1rem;
          position: absolute;
          left:0.5rem;
          height:2px;
          background: $main-color;
          margin-top:-1px;
          top:50%;
          @include transition;
          &:before,
          &:after{
            content:"";
            width:$hamburger-width - 1rem;
            left:0;
            height:2px;
            background: $main-color;
            position: absolute;
            @include transition;
          }
          &:before{
            top:-1rem;
          }
          &:after{
            bottom:-1rem;
          }
        }
      }
    }
    &.open{
      .header-hamburger{
        .open-menu{
          span{
            background: transparent;
            &:before{
              top:0;
              @include rotate(45deg);
            }
            &:after{
              top:0;
              @include rotate(-45deg);
            }
          }
        }
      }
      .header-menu{
        z-index: 1;
        opacity: 1;
        transform: translate3d(0,0,0);
        pointer-events: all;
      }
    }
  }

  &.scrolled {
    background: $base-white;
    border-bottom: 1px solid $base-grey;

    .header {
      padding: 1.5rem 0;

      .header-menu {
        top: 8.3rem;
      }
    }
  }
}

.EditMode,
.DesignMode {
  header {
    position: static;
  }
}

footer{
  @include pux-static-font(16px);
  padding: 5rem 0 3rem;
  background: $main-color;
  color: $base-white;

  .footer{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &-col {
      margin: 0 3rem 3rem 0;
      @include media(xl) {
        margin: 0 0 3rem 0;
      };

      &.smaller {
        max-width: 160px;


        &:first-of-type {
          margin-right: 6rem;
        }
      }

      &.with-cols {
        max-width: 430px;

        @include media(xl) {
          margin-left: 15rem;
        };

        .footer-col-inner {
          display: flex;
          flex-wrap: wrap;

          .top {
            width: 100%;
          }

          .left, .right {
            width: 100%;

            @include media(xs) {
              width: 50%;
            };
          }
        }

        a {
          font-weight: 400;
          display: block;
        }
      }

      &.last {
        text-align: right;
        margin-left: auto;
        margin-right: 0;

        img {
          margin-bottom: 30px;
        }

        .separator {
          margin: 0 12px;
        }

        a {
          color: $color-link;

          &:hover, &:focus {
            text-decoration: underline;
          }
        }
      }
    }
  }

  a {
    color: $base-white;
    font-weight: 400;

    &:hover, &:focus {
      text-decoration: none;
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  li {
    margin-bottom: 1.5rem;
  }
}

.is-sm{
    display:none;
    @include media(sm){
        display:block;
    }
}

.is-md{
    display:none;
    @include media(md){
        display:block;
    }
}

.is-lg{
    display:none;
    @include media(lg){
        display:block;
    }
}

.is-xl{
    display:none;
    @include media(xl){
        display:block;
    }
}

.is-xxl{
    display:none;
    @include media(xxl){
        display:block;
    }
}

//* __Lazy Load__ */
@keyframes animateLoaded__ {
  0% {
    opacity: 0;
    filter: blur(10px);
  }
  100% {
    opacity: 1;
    filter: blur(0);
  }
}

[data-lazy-type] {
  &.loaded {
    animation: $imgLoadAnimation;
  }
}

.after-header {
  padding-top: 14.3rem;
}

hr {
  display: none;
}

#sidebar {
  display: none;

  @include media(lg) {
    position: fixed;
    top: 320px;
    right: 0;
    z-index: 10;
    display: flex;
    transform-origin: 85% 85%;
    transform: rotate(90deg) scale(0.9);
  };


  @include media(xl) {
    top: 400px;
  };

  &>* {
    height: 40px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    padding: 0 12px;
    color: $base-white;
    transition: all ease-out 200ms;
    text-decoration: none !important;

    &:hover {
      text-decoration: none;
      transform: translate3d(0, 10px, 0);
    }
  }

  .mail {
    background: $color-green;
    font-weight: 400;
    font-size: 16px;

    &:before {
      color: $base-white;
      content: "\e90e";
      font-size: 17px;
      margin-right: 10px;
    }
  }

  .fb {
    font-size: 0;
    background-color: #3b5998;

    &:before {
      content: "\e906";
      color: $base-white;
      font-size: 17px;
    }
  }

  .in {
    font-size: 0;
    background-color: #0d77b7;

    &:before {
      content: "\e90f";
      color: $base-white;
      font-size: 17px;
    }
  }
}

.narrow-text {
  max-width: 89rem;
  margin: 0 auto;
}

.center-content {
  text-align: center;
}

.grey {
  background-color: $base-grey;
}

.blue {
  background-color: $color-blue;
  color: $base-white;

  h1, h2, h3, h4 {
    color: $base-white;
  }
}

.mb-220 {
  margin-bottom: 10rem;
}

.mt-130 {
  margin-top: 13rem;
}

.logos {
  display: flex;
  align-items: center;
  padding: 4.5rem 0 10rem;
  flex-wrap: wrap;

  .logo {
    padding: 2rem ;
    text-align: center;
    width: 50%;

    @include media(lg) {
      width:  25%;
    };

    img {
      filter: grayscale(100%);
      opacity: 0.5;
    }
  }
}

.narrow {
  max-width: 88rem;
  margin-left: auto;
  margin-right: auto;
}

.info-icons {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .info-icon {
    width: 100%;
    display: flex;
    margin-bottom: 5rem;

    @include media(lg) {
      width: calc(50% - 2.5rem);
    };

    .left {
      margin-right: 4rem;
      width: 19.2rem;
      flex-shrink: 0;

      img {
        width: 19.2rem;
      }
    }

    .right {
      flex-shrink: 1;

      @include media(lg) {
        max-width: 40rem;
      };
    }

    h3 {
      margin: 5rem 0 2rem;
    }
  }
}

.pager {

  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
    text-align: center;

    li {
      display: inline-block;
      padding: 15px;

      &:before {
        display: none;
      }
    }
  }
}
