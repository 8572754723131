
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/pux-content.scss";
@import "_mixins/pux/scale.scss";
@import "_variables.scss";

.content-row {
  display: flex;
  flex-wrap: wrap;

  &-img {
    position: relative;
    width: 100%;
    order: 3;
    text-align: center;

    @include media(lg) {
      width: 67%;
      order: 1;
    };

    img {

      @include media(lg) {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -55%, 0);
      };
    }
  }

  &-text {
    padding: 11rem 0 0 2.5rem;
    width: 100%;
    order: 2;

    @include media(lg) {
      padding: 11rem 0rem 11rem 2.5rem;
    };

    .btn {
      margin: 1rem 0;

      &-default {

        &:hover {
          // border-color: $color-green;
          // background: $color-green;
          // color: $color-blue;
        }
      }
    }

    @include media(lg) {
      width: 33%;
    };
  }
}
