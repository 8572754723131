@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/pux-content.scss";
@import "_mixins/pux/scale.scss";
@import "_variables.scss";

.modules {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 5rem;

  .module {
    background: $base-white;
    padding: 3rem 9rem 3rem 5rem;
    cursor: pointer;
    transition: all ease-out 200ms;
    margin-bottom: 5rem;
    position: relative;
    width: 100%;

    @include media(md) {
      width: calc(50% - 2.5rem);
    };

    @include media(xl) {
      width: calc(33.333% - 3.33rem);
    };

    &:before {
      content: "\e903";
      height: 4.4rem;
      width: 4.4rem;
      @include flexCenter;
      background: $base-grey;
      color: $color-blue;
      font-size: 2rem;
      top: 3rem;
      right: 2rem;
      position: absolute;
      border-radius: 50%;
      padding-left: 0.3rem;
    }

    &-name {
      @include pux-scale-with-min("font-size", 25px, 20px);
      @include pux-scale-with-min("line-height", 28px, 20px);
      font-weight: bold;
      color: $color-blue;
      margin-bottom: 2rem;
      transition: all ease-out 200ms;
    }

    &-description {
      transition: all ease-out 200ms;
    }

    &-detail {
      display: none;

      position: relative;
      top: 0;
      left: 0;
      width: 100%;
      // height: 100%;
      // background: rgba($color-blue, 0.9);
      cursor: default;
      padding-bottom: 5rem;

      .pux-container {
        padding: 0;
      }

      .module-detail-body {
        display: flex;
        flex-wrap: wrap;

        .left, .right {
          width: 100%;

          @include media(md) {
            width: 50%;
          };
        }

        .left {
          padding: 5rem;
          background: $base-white;
          overflow: auto;
          max-height: 60vh;

          .module-name {
            color: $color-blue;
          }
        }

        .right {
          position: relative;
          height: 35vh;

          @include media(md) {
            height: unset;
          };

          .bg {
            @include absoluteCover;
            background-size: cover !important;
            background-position: center center !important;
          }

          &.video {
            @include flexCenter();
            cursor: pointer;

            &:before {
              content: "\e910";
              color: $color-link;
              font-size: 4.5rem;
              height: 8rem;
              width: 8rem;
              background: $base-white;
              display: inline-block;
              @include flexCenter();
              transition: all ease-in-out 200ms;
              opacity: 1;
              border-radius: 50%;
              padding-left: 1rem;
              z-index: 1;
            }

            &:hover {
              text-decoration: none;

              &:before {
                opacity: 0.7;
              }
            }
          }
        }
      }
    }

    &-close {
      @include flexCenter;
      width: 4.3rem;
      height: 4.3rem;
      background: $base-white;
      border-radius: 50%;
      cursor: pointer;
      transition: all ease-out 200ms;
      position: absolute;
      top: 2rem;
      right: 2rem;
      z-index: 2;

      &:before {
        content: "\e905";
        font-size: 3.5rem;
        color: $base-black;
        padding: 0.1rem 0 0 0.1rem;

        @include media(md) {
          font-size: 1.5rem;
        };
      }

      &:hover {
        opacity: 0.6;
      }
    }

    &:hover {
      background: $color-blue;

      .module-name, .module-description {
        color: $base-white;
      }
    }

    &:after {
      content: "";
      @include triangle-bottom(20px, 20px, 20px, $main-color);
      position: absolute;
      top: 100%;
      display: inline-block;
      transform: translate3d(0, -10px, 0) scale(0);
      z-index: 0;
      transition: all ease-in-out 200ms;
    }

    transition: all ease-in-out 200ms;
    &.active {
      background: $main-color;
      color: $base-white;

      .module-name {
        color: $base-white;
      }

      &:after {
        transform: translate3d(0,0,0) scale(1);
      }
    }


  }

  .detail-placeholder {
    width: 100%;
  }
}
