
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/pux-content.scss";
@import "_mixins/pux/scale.scss";
@import "_variables.scss";

.references {
  margin-top: 5rem;
  display: flex;
  flex-wrap: wrap;

  .reference {
    border: 2px solid $base-grey;
    padding: 4rem;
    width: 100%;
    margin-bottom: 5rem;

    @include media(lg) {
      width: calc(33.333% - 3.333rem);
      margin-right: 4.9rem;
    };

    &:nth-of-type(3n + 3) {
        @include media(lg) {
          margin-right: 0;
        }
    }

    .img-wrap {
      margin-bottom: 4rem;

      @include media(lg) {
        display: flex;
        align-items: center;
        min-height: 8.5rem;
      };

      img {
        filter: grayscale(100%);
        opacity: 0.5;
      }
    }

    h3 {
      margin: 0 0 1.5rem;
    }

    strong {
      display: block;
      margin-bottom: 3rem;
    }
  }
}

.references-slider {

  .reference {
    margin: 0;

    @include media(lg) {
      margin: 0 2.5rem;
    };
  }

  .slick-arrow {
    top: 50%;
    margin-top: -2.7rem;
    background: $color-blue;

    &.slick-next {
      left: auto;
      right: -3rem;

      @include media(lg) {
        right: -1.5rem;
      };
    }

    &.slick-prev {
      left: -3rem;

      @include media(lg) {
        left: -1.5rem;
      };
    }
  }
}

.slider-container {
  margin-left: auto;
  margin-right: auto;
  padding-left: $container-padding-left;
  padding-right: $container-padding-right;
  max-width: $container-max-width + 5rem;
  overflow: hidden;
  @include media(md){
      padding-left: $container-padding-left-md;
      padding-right: $container-padding-right-md;
  }
}
